import { useControlled as muiUseControlled } from '@material-ui/core';
import { UseControlledProps } from '@material-ui/core/utils/useControlled';
import { Overwrite } from 'edgeco/types';
import { useState } from 'react';

type UseControlledArgs<T> = Overwrite<
  UseControlledProps<T>,
  {
    default: UseControlledProps<T>['default'] | (() => T);
  }
>;

export function useControlled<T = unknown>(
  args: UseControlledArgs<T>
): [T, (newValue: T) => void] {
  // Material-UI useControlled doesn't support a function for initial state
  // Using useState as an intermediary allows us to do costly operations inside the function
  // that will only run once
  const [defaultValue] = useState<T | undefined>(args.default);
  return muiUseControlled({
    ...args,
    default: defaultValue,
  });
}
