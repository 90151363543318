import { IconButton, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { SubComponentProps } from 'react-table';
import clsx from 'clsx';
import IndicatorBar from '../../common/IndicatorBar';

const useStyles = makeStyles(({ extensions }: EdgeCoTheme) => ({
  button: {
    padding: 7,
  },
  expanded: {
    color: extensions.color.primary,
  },
  root: {
    height: 41,
    display: 'flex',
    alignItems: 'center',
  },
}));

function ExpanderCell({ row }: SubComponentProps) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <IndicatorBar active={row.wasPreviouslyExpanded} />
        <IconButton
          className={clsx(classes.button, row.isExpanded && classes.expanded)}
          aria-label="expand row"
          size="small"
          {...row.getToggleRowExpandedProps()}
        >
          <FontAwesomeIcon
            size="sm"
            icon={row.isExpanded ? faChevronUp : faChevronDown}
          />
        </IconButton>
      </div>
    </>
  );
}

export default ExpanderCell;
