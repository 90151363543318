import { FilterRequestFilter } from 'edgeco/graphql/advisor-summary/@types/filter-specifications';
import { DatePeriod } from 'edgeco/types';
import { Moment } from 'moment';
import { periodToRange } from './date-utils';

export const AscPrefix = '+';
export const DescPrefix = '-';
export enum FilterOperator {
  Equals = '==',
  NotEquals = '!=',
  GreaterThan = '>',
  LessThan = '<',
  GreaterThanOrEqual = '>=',
  LessThanOrEqual = '<=',
  Contains = '=*',
  StartsWith = '^=',
  EndsWith = '=^',
}

const convertToDateFilterFormat = (date: Moment): string => date.format();

export const getDateFilters = (
  dateFieldName: string,
  datePeriod: DatePeriod,
  startDate?: Moment,
  endDate?: Moment
): FilterRequestFilter[] => {
  const property = dateFieldName[0].toLowerCase() + dateFieldName.slice(1);
  if (datePeriod && datePeriod !== 'Custom') {
    const dates = periodToRange(datePeriod ?? 'Yesterday');
    return [
      {
        operator: FilterOperator.GreaterThanOrEqual,
        property,
        value: convertToDateFilterFormat(dates[0]),
      },
      {
        operator: FilterOperator.LessThanOrEqual,
        property,
        value: convertToDateFilterFormat(dates[1]),
      },
    ];
  }

  const filters: FilterRequestFilter[] = [];

  const startDateFilter: FilterRequestFilter | null = startDate
    ? {
        operator: FilterOperator.GreaterThanOrEqual,
        property,
        value: convertToDateFilterFormat(startDate),
      }
    : null;

  const endDateFilter: FilterRequestFilter | null = endDate
    ? {
        operator: FilterOperator.LessThanOrEqual,
        property,
        value: convertToDateFilterFormat(endDate),
      }
    : null;
  if (endDateFilter) filters.push(endDateFilter);
  if (startDateFilter) filters.push(startDateFilter);

  return filters;
};

export const getEqualsToFilters = (
  field: string,
  values: (string | number)[]
): FilterRequestFilter[] => {
  return values.map(
    (x) =>
      ({
        operator: FilterOperator.Equals,
        value: x,
        property: field,
      } as FilterRequestFilter)
  );
};
