import { Cell, DataKey } from 'recharts';

import uniqueId from 'lodash.uniqueid';
import { get } from 'lodash';
import { ChartDataShape, ChartNestedDataShape } from 'edgeco/types';
import defaultColorScheme, {
  ColorScheme,
  ColorSchemeProvider,
} from '../../assets/colorScheme';
import { ActiveState, DataAccessor, Series } from './@types';

export function getValueByDataKey<T>(obj: T, dataKey: DataKey) {
  if (obj === undefined) return undefined;
  if (typeof dataKey === 'function') {
    return dataKey(obj);
  }
  return get(obj, dataKey);
}

export function dataToCells<T = any>(
  data: ReadonlyArray<T>,
  dataKey: DataAccessor<T>,
  activeState?: ActiveState,
  colorScheme?: ColorScheme
): JSX.Element[] {
  return data.map((_, index) => {
    const isActive = activeState?.getIsActive(dataKey, index);
    const activeEventHandlers = activeState?.getCellProps({
      key: dataKey,
      index,
    });

    const fill = colorScheme
      ? ColorSchemeProvider.forIndexFromScheme(colorScheme, index, isActive)
      : defaultColorScheme.forIndex(index, data.length, isActive);

    return (
      <Cell {...activeEventHandlers} key={uniqueId('cell-')} fill={fill} />
    );
  });
}

/**
 * "Rounds" number up to the nearest half standard value (i.e. (330,000) => 350,000)
 * @param value Number value to be rounded
 */
const niceNum = (value: number) => {
  const exponent = Math.floor(Math.log10(value));
  const fraction = value / 10 ** exponent;

  return Math.ceil(fraction / 0.5) * 0.5 * 10 ** exponent;
};

/**
 * Calculates the range of a chart domain from a ChartDataShape
 * @param dataValues Chart shape of the domain. Assumes data field is an object with numerical values.
 * @param currentMax The current max value of the domain to compare the calculated one too.
 * @param minOverride Override minimum value of the range.
 * @param dataKeys If more than 1 source of data inside the ChartDataShape or if data is nested.
 */
const getChartDomain = (
  dataValues: ChartDataShape<Record<any, number>>[],
  currentMax?: number,
  minOverride?: number,
  dataKeysOverride?: DataAccessor[]
): [number, number] => {
  if (dataValues.length < 1) return [0, currentMax ?? 100];

  const values = dataValues
    .map((chartDataShape) => {
      const dataShapeValues = dataKeysOverride
        ? dataKeysOverride.map((d) => d(chartDataShape))
        : Object.values(chartDataShape.data);

      return dataShapeValues.filter(
        (v) => typeof v === 'number' && !Number.isNaN(v)
      ) as number[];
    })
    .flat();

  const max = Math.max(...values, currentMax ?? 100);
  const min = minOverride ?? Math.min(...values);
  return [min, max];
};

/**
 * Calculates the friendly chart ticks for a given chart range
 * @param domainRange Tuple of Max and Min values of domain
 * @param maxTicks Number of ticks to render
 */
const getChartTicks = (domainRange: [number, number], maxTicks: number = 6) => {
  const range = niceNum(domainRange[1] - domainRange[0]);
  const tickSpacing = niceNum(range / maxTicks - 1);

  return new Array<number>(maxTicks).fill(0).reduce((acc, _, idx) => {
    const next = idx === 0 ? 0 : acc[idx - 1] + tickSpacing;
    acc.push(next);
    return acc;
  }, [] as number[]);
};

function getSeries(configs: Series[]) {
  return configs.map((keyOrSeries) => {
    let asSeries: Series;
    if (typeof keyOrSeries !== 'object') {
      asSeries = {
        dataKey: keyOrSeries,
      };
    } else {
      asSeries = keyOrSeries as Series;
    }
    return asSeries;
  });
}

function getMultiSeriesKeys<T>(
  data:
    | any[]
    // TODO - data-structure: remove once everything is flat
    | ChartNestedDataShape,
  ...exclusions: (keyof T)[]
) {
  // TODO - data-structure: remove once everything is flat
  const nested = data as ChartNestedDataShape;
  const flat = data as any[];
  if (nested.chartData) {
    return Object.keys(nested.chartData[0]?.data);
  }
  // end todo
  return Object.keys(flat[0]).filter(
    (key) => !(exclusions as string[]).includes(key)
  );
}

function generateGetBoundingClientRect(x = 0, y = 0) {
  return () => ({
    width: 0,
    height: 0,
    top: y,
    right: x,
    bottom: y,
    left: x,
  });
}

export {
  getChartDomain,
  getChartTicks,
  getSeries,
  getMultiSeriesKeys,
  generateGetBoundingClientRect,
};
