import {
  Grid,
  makeStyles,
  List,
  Collapse,
  fade,
  Theme,
  Button,
  ListSubheader,
  useTheme,
  GridSize,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback } from 'react';

import match from 'autosuggest-highlight/match';

import CheckboxList from '../lists/CheckboxList';
import ListItem from '../lists/ListItem';
import { SELECTION_GROUP } from './constants';
import IndicatorBar from '../common/IndicatorBar';

import IconButton from '../buttons/IconButton';
import { TransferItem, TransferListOptions } from './@types';
import { GroupType } from '../lists/@types';

const useStyles = makeStyles(
  ({ spacing, extensions: { color, borders } }: Theme) => ({
    addButtonStyle: {
      height: '40px',
      padding: '8px  8px',
    },
    searchFieldStyle: {
      paddingLeft: spacing(1),
      width: '60%',
    },
    scrollContainer: {
      overflowY: 'auto',
      maxHeight: 'inherit',
    },
    baseListStyle: {
      padding: 0,
    },
    leftStyle: {
      backgroundColor: color.background,
    },
    leftListHeaderStyle: {
      borderBottom: borders.light,
      paddingBottom: 4,
    },
    listText: {
      fontSize: '1.8rem',
      fontWeight: 400,
      lineHeight: '2.1rem',
    },
    headerStyle: {
      backgroundColor: color.backgroundContrastDark,
      color: color.text.contrast,
      '&:hover': {
        backgroundColor: fade(color.backgroundContrastDark, 0.75),
      },
      marginBottom: spacing(1 / 2),
      marginTop: spacing(1 / 2),
      padding: spacing(0, 1 / 2),
    },
    highlightedText: {
      borderBottom: `2px solid ${color.backgroundContrastDark}`,
    },
    selectAllItem: {},
    rootList: {},
    pinnedListItem: {},
  })
);

type LeftListProps = {
  renderOptions: TransferListOptions;
  handleChecked: (item: TransferItem[], currentGroup: GroupType) => void;
  onManualTransfer: (destination: GroupType, ...items: TransferItem[]) => void;
  allValues: Map<GroupType, TransferItem[]>;
  listMap: Map<GroupType, TransferItem[]>;
  classes: Partial<ReturnType<typeof useStyles>>;
  checked: Set<string>;
  addText?: string;
  searchText: string;
  handleCollapsible: (group: GroupType) => void;
  expanded: Set<GroupType>;
  gridSize?: boolean | GridSize | undefined;
  showCheckedIndicator?: boolean;
};

function LeftList({
  renderOptions,
  handleChecked,
  allValues,
  listMap,
  classes,
  checked,
  addText = 'Add',
  searchText,
  onManualTransfer,
  handleCollapsible,
  expanded,
  gridSize,
  showCheckedIndicator,
}: LeftListProps) {
  const renderedClasses = useStyles({ classes });
  const theme = useTheme();
  const disableSelected = renderOptions.variant === 'left-right';

  const applySearchFilterTextHighlight = useCallback(
    (item: TransferItem) => {
      const matches = match(item.searchText, searchText);
      return (
        <div aria-label={item.label} className={renderedClasses.listText}>
          <span
            className={
              matches.length > 0 ? renderedClasses.highlightedText : undefined
            }
          >
            {item.label}
          </span>
        </div>
      );
    },
    [renderedClasses.highlightedText, renderedClasses.listText, searchText]
  );

  const renderLeftList = (group: GroupType, items: TransferItem[]) => (
    <CheckboxList
      key={`${group}-list`}
      showSelectAll={renderOptions.showSelectGroup}
      listId={group}
      listItems={items}
      handleCheck={handleChecked}
      checkedItems={checked}
      classes={{
        rootList: renderedClasses.rootList,
        selectAllItem: renderedClasses.selectAllItem,
        pinnedListItem: renderedClasses.pinnedListItem,
      }}
      listProps={{
        className: renderedClasses.baseListStyle,
      }}
      renderListItem={(item) => ({
        text: {
          primary: () => applySearchFilterTextHighlight(item),
        },
        disabled:
          item.disabled ||
          (disableSelected &&
            (item.currentGroup === SELECTION_GROUP ||
              listMap.get(SELECTION_GROUP)?.find((i) => i.id === item.id) !==
                undefined)),
      })}
    />
  );

  return (
    <Grid
      item
      xs={gridSize ?? 'auto'}
      className={clsx(
        renderedClasses.leftStyle,
        renderedClasses.scrollContainer
      )}
    >
      <List className={renderedClasses.baseListStyle}>
        <ListSubheader
          disableGutters={true}
          className={clsx(
            renderedClasses.leftStyle,
            renderedClasses.leftListHeaderStyle
          )}
        >
          <div
            hidden={renderOptions.variant === 'single'}
            className={renderedClasses.addButtonStyle}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                onManualTransfer(
                  SELECTION_GROUP,
                  ...[...allValues.values()]
                    .flat()
                    .filter((x) => checked.has(x.id))
                )
              }
            >
              {addText}
            </Button>
          </div>
        </ListSubheader>

        {[...allValues].map(([group, items]) => {
          const anyChecked = items.some((item) => checked.has(item.id));
          const header = (
            <ListItem
              className={renderedClasses.headerStyle}
              button={true}
              isNarrow={true}
              text={{
                primary: (
                  <div className={renderedClasses.listText}>{group}</div>
                ),
              }}
              primaryAction={{
                props: {
                  style: {
                    minWidth: 0,
                  },
                },
                iconButton: (
                  <>
                    {showCheckedIndicator && (
                      <IndicatorBar
                        active={anyChecked}
                        color={theme.extensions.color.accordionIndicator}
                      />
                    )}
                    <IconButton
                      size="small"
                      onClick={() => handleCollapsible(group)}
                      variant={
                        expanded.has(group) ? 'chevronUp' : 'chevronDown'
                      }
                      primaryColor={theme.extensions.color.text.contrast}
                    />
                  </>
                ),
              }}
            />
          );
          const collapse = (
            <Collapse in={expanded.has(group)} timeout="auto" unmountOnExit>
              {renderLeftList(group, items)}
            </Collapse>
          );

          return (
            <React.Fragment key={`${group}-list-item`}>
              {header}
              {collapse}
            </React.Fragment>
          );
        })}
      </List>
    </Grid>
  );
}

export default LeftList;
