import { makeStyles, WithStyles } from '@material-ui/core';
import { DropdownLineWithChevron } from 'edgeco/components/data-visualization/Table/Filters/DropdownLineWithChevron';
import { AccordionClassKey, AccordionHeaderAndSubsProps } from './@types';

import AccordionContext from './AccordionContext';

const useStyles = makeStyles(({ extensions: { color } }: EdgeCoTheme) => ({
  root: {
    userSelect: 'none',
  },
  mainText: {
    fontSize: 24,
    fontWeight: 'bold',
    fontFamily: 'arial',
    marginLeft: 25,
    userSelect: 'none',
  },
  subText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: color.borderDarker,
    padding: 10,
    marginTop: '-0.625rem',
    marginBottom: '12rem',
    marginLeft: '8.5%',
    userSelect: 'none',
  },
}));

export function AccordionHeaderAndSubs({
  group,
}: WithStyles<AccordionClassKey> & AccordionHeaderAndSubsProps) {
  const classes = useStyles();
  const { text, subText, id } = group;
  return (
    <AccordionContext.Consumer>
      {(ctx) => (
        <div className={classes.root} onClick={() => ctx.toggle(id)}>
          <div className={classes.mainText}>{text}</div>
          <DropdownLineWithChevron
            direction={ctx.expanded.get(id) ? 'up' : 'down'}
          />
          <div className={classes.subText}>{subText}</div>
        </div>
      )}
    </AccordionContext.Consumer>
  );
}

export default AccordionHeaderAndSubs;
