import {
  TextField,
  TextFieldProps,
  makeStyles,
  debounce,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';

const useStyles = makeStyles(({ spacing }) => ({
  label: {
    margin: spacing(0, 0, 1, 0.5),
    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      position: 'relative',
      transform: 'none',
    },
  },
  input: {
    fontSize: '1.8rem',
    paddingLeft: spacing(0.5),
    height: 34,
  },
  container: {
    display: 'inline-flex',
  },
  actions: {
    display: 'inline-box',
    alignSelf: 'flex-end',
    paddingLeft: spacing(1),
    height: 34,
  },
}));

export type TextBoxProps = TextFieldProps & {
  actions?: React.ReactNode;
  debounce?: boolean;
  maxLength?: number;
};

export function TextBox({
  actions,
  debounce: debounceProp,
  value: valueProp,
  onChange: onChangeProp,
  maxLength,
  ...textFieldProps
}: TextBoxProps) {
  const [value, setValue] = useState('');
  const [debouncing, setDebouncing] = useState(false);

  const onChangeDebounce = useCallback(
    debounce(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeProp?.(event);
        setDebouncing(false);
      },
      debounceProp ? 200 : 0
    ),
    [debounceProp, onChangeProp]
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value: eventValue } = event.target;
      if (maxLength && eventValue.length > maxLength) return;
      setValue(eventValue);
      setDebouncing(true);
      event.persist();
      onChangeDebounce(event);
    },
    [maxLength, onChangeDebounce]
  );

  const classes = useStyles();
  let defaultedProps: TextFieldProps = {
    InputLabelProps: {
      className: classes.label,
      shrink: true,
    },
    InputProps: {
      className: classes.input,
      notched: false,
    },
    variant: 'outlined',
  };

  defaultedProps = {
    ...defaultedProps,
    ...textFieldProps,
  };

  return (
    <div className={classes.container}>
      <TextField
        {...defaultedProps}
        value={debouncing ? value : valueProp ?? value}
        onChange={onChange}
      />
      {actions && <div className={classes.actions}>{actions}</div>}
    </div>
  );
}

export default TextBox;
