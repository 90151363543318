import { useMemo, PropsWithChildren } from 'react';
import { DataGrid, DataGridProps, GridColDef } from '@mui/x-data-grid';
import { WithStyles, withStyles } from '@material-ui/styles';
import { OptionalClasses } from 'edgeco/types/material-ui';
import moment, { Moment } from 'moment';

const styles = () => ({
  root: {
    width: '100%',
  },
});

export type Message = {
  date: Date | Moment;
  from: string;
  subject: string;
};

export type InboxMessageProps = PropsWithChildren<{
  classes?: OptionalClasses<InboxMessagesClassKey>;
  messages: Message[];
  dateFormat?: string;
}> &
  Partial<Omit<DataGridProps, 'columns' | 'data'>>;
export type InboxMessagesClassKey = keyof ReturnType<typeof styles>;

const InboxMessages = (
  props: WithStyles<InboxMessagesClassKey> & InboxMessageProps
) => {
  const { classes, messages, dateFormat = 'l', ...rest } = props;

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'date',
        headerName: 'Date',
        width: 150,
        type: 'date',
        valueFormatter: (params) => {
          let value = params.value as Date | Moment;
          if (!moment.isMoment(value)) value = moment(value);
          return value.format(dateFormat);
        },
      },
      { field: 'from', headerName: 'From', width: 150 },
      {
        field: 'subject',
        headerName: 'Subject',
        flex: 1,
        renderCell: (params) => {
          let value = params.value as string;
          let prefix: React.ReactNode = '';
          if (value.startsWith('RE:')) {
            value = value.replace('RE:', '');
            prefix = <strong>RE:{'\u00a0'}</strong>;
          }
          return (
            <>
              {prefix}
              {value}
            </>
          );
        },
      },
    ],
    [dateFormat]
  );
  return (
    <DataGrid
      className={classes.root}
      autoHeight
      rows={messages}
      columns={columns}
      pageSize={5}
      checkboxSelection
      {...rest}
    />
  );
};

const Styled = withStyles(styles)(InboxMessages);
export { Styled as InboxMessages };
export default Styled;
