import { Button, Grid, makeStyles } from '@material-ui/core';
import useModal from 'edgeco/components/modal/useModal';
import React, { useMemo } from 'react';
import * as Mui from '@material-ui/core';
import { TableInstance } from 'react-table';
import { TABLE_EXPANDER_ID } from './constants';
import TablePagerActions from './TablePagerActions';
import useColumnPicker from './useColumnPicker';
import ExportButton, { ExportButtonProps } from './ExportButton';

const useStyles = makeStyles(({ extensions: { color, table } }) => ({
  actionsRow: {
    width: '100%',
    borderBottom: `1px solid ${color.borderLight}`,
    overflow: 'hidden',
  },
  actionsCell: {
    height: table.pager.height,
    paddingLeft: '32px',
  },
  actionButtons: {
    padding: '8px 0 8px 32px',
  },
  pagerCell: {
    '& > div': {
      padding: 0,
    },
  },
  rightActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

type TableActionsProps<D extends object> = {
  instance: TableInstance<D>;
  exportProps: Omit<ExportButtonProps<D>, 'instance'>;
};

// Note: MUI Pagination has some issues when setting page from query params
// https://github.com/mui-org/material-ui/issues/15616
export function TableActions<D extends object = {}>({
  instance,
  exportProps,
}: TableActionsProps<D>) {
  const { pagerCell, actionsRow, actionsCell, actionButtons, rightActions } =
    useStyles();

  const {
    gotoPage,
    allColumns,
    columnDefinitions,
    initialHiddenColumns,
    initialSelectedColumns,
    setHiddenColumns,
    setColumnOrder,
    pageCount,
    state: { pageIndex, pageSize },
  } = instance;

  const hasExpander = useMemo(
    () => allColumns.some((x) => x.id === TABLE_EXPANDER_ID),
    [allColumns]
  );

  const {
    TransferComponent,
    hidden,
    selected,
    resetColumns,
    applyColumnState,
  } = useColumnPicker({
    addText: 'Add Column',
    columnDefinitions,
    initialHiddenColumns,
    initialSelectedColumns,
    hasExpander,
  });

  const { Modal: ColumnPickerModal, setOpen: setColumnPickerModalOpen } =
    useModal({
      isDraggable: false,
      title: 'Customize Columns',
      content: TransferComponent,
      submitButton: {
        text: 'Apply Changes',
      },
      onSubmit: () => {
        setHiddenColumns(hidden);
        setColumnOrder(selected);
        applyColumnState();
      },
      onCancel: () => resetColumns(),
    });

  const handleOpenColumnPicker = () => {
    setColumnPickerModalOpen(true);
  };

  // TODO: Customize Columns button actually is padded from Filter Box
  return (
    <>
      <Grid
        container
        className={actionsRow}
        justify="space-between"
        alignItems="center"
        direction="row"
      >
        <Grid item className={actionButtons}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenColumnPicker}
          >
            Customize Columns
          </Button>
          {ColumnPickerModal}
        </Grid>
        <Grid item className={actionsCell}>
          <div className={rightActions}>
            <ExportButton instance={instance} {...exportProps} />
            <Mui.TablePagination
              component="div"
              className={pagerCell}
              rowsPerPageOptions={[]}
              count={-1}
              rowsPerPage={pageSize}
              page={pageIndex}
              onChangePage={(_, targetPage) => gotoPage(targetPage)}
              ActionsComponent={(actionsProps) => (
                <>
                  <TablePagerActions pageCount={pageCount} {...actionsProps} />
                </>
              )}
              labelDisplayedRows={() => null}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default TableActions;
