import { makeStyles } from '@material-ui/core';

const duration = 1.4;
const offset = 187;

const useStyles = makeStyles({
  root: {
    zIndex: 999,
    margin: 'auto',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  spinner: {
    animation: `$rotator ${duration}s linear infinite`,
    display: 'block',
    margin: 'auto',
    top: '50%',
    left: '50%',
  },
  path: {
    strokeDasharray: offset,
    strokeDashoffset: 0,
    transformOrigin: 'center',
    animation: `$dash ${duration}s ease-in-out infinite,
                $colors ${duration * 4}s ease-in-out infinite`,
  },
  '@keyframes rotator': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(270deg)',
    },
  },
  '@keyframes colors': {
    '0%': {
      stroke: '#5fbe43',
    },
    '25%': {
      stroke: '#4f888d',
    },
    '50%': {
      stroke: '#276fab',
    },
    '75%': {
      stroke: '#4f888d',
    },
    '100%': {
      stroke: '#5fbe43',
    },
  },
  '@keyframes dash': {
    '0%': {
      strokeDashoffset: offset,
    },
    '50%': {
      strokeDashoffset: offset / 4,
      transform: 'rotate(135deg)',
    },
    '100%': {
      strokeDashoffset: offset,
      transform: 'rotate(450deg)',
    },
  },
});

function Loading() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <svg
        className={classes.spinner}
        width="65px"
        height="65px"
        viewBox="0 0 66 66"
        xmlns="https://www.w3.org/2000/svg"
      >
        <circle
          className={classes.path}
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
    </div>
  );
}

export default Loading;
