import { Constructor } from 'edgeco/types';

const serviceCache: any = {};
/**
 * HOC that provides the services as props.  Allows for mocking api services
 * @param WrappedComponent Component to receive the api services
 */
export const withServices =
  (
    WrappedComponent: React.ElementType,
    services: Record<string, Constructor<any> | any>
  ) =>
  (props: any) => {
    const activated = Object.entries(services).reduce((acc, [key, Ctor]) => {
      const isClass = Ctor.constructor !== undefined;
      if (isClass) {
        let cached = serviceCache[Ctor];
        if (!cached) {
          cached = new Ctor();
          serviceCache[Ctor] = cached;
        }
        acc[key] = cached;
      } else {
        acc[key] = Ctor;
      }
      return acc;
    }, {} as Record<string, any>);
    return <WrappedComponent {...props} {...activated} />;
  };
export default withServices;
