import numeral from 'numeral';

export const currencyFormatter = (value?: number, abbreviationMin?: number) => {
  if (!value) return null;

  let formatString = '$0.0a';

  const exponent = Math.floor(Math.log10(value));

  if (abbreviationMin && exponent < abbreviationMin) {
    formatString = '$0,0';
  }
  return numeral(value)
    .format(formatString)
    .toUpperCase()
    .replace(/\.0([A-Z])/gi, '$1')
    .replace(/\.0$/gi, '')
    .toString();
};

export const numberFormatter = (
  value?: number,
  abbreviationMin?: number,
  includeSign: boolean = false
) => {
  if (!value) return null;

  let formatString = includeSign ? '+' : '';

  const exponent = Math.floor(Math.log10(Math.abs(value)));
  if (abbreviationMin && exponent < abbreviationMin) {
    formatString += '0,0';
  } else {
    formatString += '0.0a';
  }

  return numeral(value)
    .format(formatString)
    .toUpperCase()
    .replace(/\.0([A-Z])/gi, '$1')
    .replace(/\.0$/gi, '')
    .toString();
};
