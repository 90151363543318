// ! Do not use the types in this file directly
// the only way this should be interacted with is through the theme in material-ui via useTheme, makeStyles, etc

import { ExtensionColors } from './color';
import { ColorSchemeProvider } from './colorScheme';
import fonts from './font';
import buildStyles from './styles';

export function buildExtensions(colors: ExtensionColors) {
  // this allows a consuming app to override the values
  return {
    toolbarHeight: 118,
    pageWrapper: {
      headerHeight: 90,
      spacing: {
        padding: 3,
      },
    },
    table: {
      pager: {
        height: 52,
      },
      cell: {
        padding: 7,
      },
    },
    color: colors,
    colorSchemes: {
      primary: new ColorSchemeProvider(undefined, colors.data),
      tableFooter: [colors.data.data1, colors.data.data4, colors.data.data2],
    },
    font: fonts,
    boxShadow: '0px 2px 4px 0px rgba(0,0,0,.5)',
    borders: {
      light: `1px solid ${colors.borderLight}`,
      dark: `1px solid ${colors.borderDark}`,
    },
    styles: buildStyles(colors),
  };
}

export type ThemeExtensions = ReturnType<typeof buildExtensions>;
export default buildExtensions;
