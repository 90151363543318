import { DataAccessor } from 'edgeco/components/data-visualization/@types';

export const DatePeriodValues = [
  'Day',
  'Yesterday',
  'Month',
  'MTD',
  'Quarter',
  'Year',
  'YTD',
  'T12',
  'Custom',
] as const;

export const FiscalQuarterValues = [
  'Quarter 1',
  'Quarter 2',
  'Quarter 3',
  'Quarter 4',
] as const;

export const NumberFormatValues = ['CHANGE', 'CURRENCY'] as const;

export type DatePeriod = typeof DatePeriodValues[number];
export type NumberFormat = typeof NumberFormatValues[number];
export type FiscalQuarter = typeof FiscalQuarterValues[number];

export type GenericChartMeta = Record<string, any>;

export type ToutData = {
  data: {
    format: NumberFormat;
    value: number;
  };
  description: string;
  title: string;
};

export type ColumnType = 'date';

export type ColumnDefinition = (
  | {
      group: string;
      hidden?: boolean;
    }
  | {
      group?: never;
      hidden: 'always';
    }
) & {
  header: string;
  footer?: string;
  format?: string;
  type?: ColumnType;
  sticky?: import('react-table').StickyValues;
  width?: number;
  minWidth?: number;
  tooltip?: boolean;
  tooltipWidth?: number;
  isSortable?: boolean;
};

export type ColumnDefinitionList<D> = Record<keyof D, ColumnDefinition>;

export type FormLayout<T> = {
  groupings: FormGroup<T>[];
};

export type FormGroup<T> = {
  values: FormValue<T>[];
};

export type FormValue<T> = {
  title: string;
  format?: string;
  type?: ColumnType;
  getValue: DataAccessor<T>;
};
