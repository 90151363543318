import {
  createNamedContext,
  useGenericContext,
  useGenericCreateContext,
} from 'edgeco/hooks/genericContext';
import { TableContextType } from './@types';

// TableContext should never be exported, it is not type safe and shouldn't be used directly
const TableContext = createNamedContext('TableContext', {
  onSaveFilters: () => {},
  reloadFromQuery: () => {},
});

export const useCreateTableContext = <T extends object = {}>(
  contextValues: TableContextType<T>
) => {
  return useGenericCreateContext(contextValues, TableContext);
};

export const useTableContext = <T extends object = {}>() =>
  useGenericContext<TableContextType<T>>(TableContext);
