import { makeStyles, Grid } from '@material-ui/core';
import { DataGridLayout } from './types';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    paddingBottom: theme.spacing(0.5),
    textDecoration: 'underline',
  },
  table: {
    marginBottom: theme.spacing(1),
    height: '90%',
    lineHeight: '1.4rem',
  },
  cell: {
    minWidth: 10,
  },
}));

type DataGridProps<D extends object = {}> = {
  className?: string;
  data: D;
  layout: DataGridLayout<D>;
};

export function DataGrid<D extends object = {}>({
  className,
  data,
  layout,
}: DataGridProps<D>) {
  const classes = useStyles();

  return (
    <Grid className={className} container spacing={0}>
      {layout.groupings.map((x) => {
        return (
          <Grid container xs={12} spacing={0} className={classes.table}>
            {x.values.map((y) => {
              return (
                <Grid item xs={4} sm={2} className={classes.cell}>
                  <div className={classes.title}>{y.title}</div>
                  <div>{y.getValue(data)}</div>
                </Grid>
              );
            })}
          </Grid>
        );
      })}
    </Grid>
  );
}

export default DataGrid;
