import {
  DialogActions,
  Button,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { ModalButton } from './@types';

// TODO: Extract styles into theme
// TODO: Define cancel button
const styles = ({ shadows, extensions: { borders } }: Theme) => ({
  footerStyle: {
    backgroundColor: '#FFF',
    boxShadow: shadows[4],
    borderTop: borders.light,
  },
  cancel: {},
  submit: {},
});

export type ConfirmActionsClassKey = keyof ReturnType<typeof styles>;

export type ConfirmActionsProps = WithStyles<ConfirmActionsClassKey> & {
  className?: string;
  submitButton?: ModalButton;
  cancelButton?: ModalButton;
  handleCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export function ConfirmActions({
  className,
  submitButton,
  cancelButton,
  handleCancel,
  handleSubmit,
  classes,
}: ConfirmActionsProps) {
  return (
    <DialogActions className={clsx(classes.footerStyle, className)}>
      {cancelButton && (
        <Button
          className={classes.cancel}
          variant="outlined"
          color="secondary"
          onClick={handleCancel}
          {...cancelButton}
        >
          {cancelButton.text}
        </Button>
      )}
      {submitButton && (
        <Button
          className={classes.submit}
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          {...submitButton}
        >
          {submitButton.text}
        </Button>
      )}
    </DialogActions>
  );
}

export { styles as confirmActionsStyle };
export default withStyles(styles, { name: 'ConfirmActions' })(ConfirmActions);
