// ! Do not use the types in this file directly
// the only way this should be interacted with is through the theme in material-ui via useTheme, makeStyles, etc
import { createMuiTheme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';

const baseTheme = createMuiTheme({});

const fonts: Record<string, CSSProperties> = {
  default: {
    fontFamily: '"Helvetica", "Arial", sans-serif',
    fontWeight: 300,
    lineHeight: '1.5em',
  },
  title: {
    fontFamily: '"Helvetica", "Arial", sans-serif',
    fontSize: '2.4rem',
  },
  infographicHeading: {
    fontSize: '1.8rem',
  },
  infographicLabel: {
    fontFamily: '"Arial","Helvetica", sans-serif',
    fontSize: '1.6rem',
  },
  infographicTickLabelSm: {
    fontFamily: '"Helvetica","Arial", sans-serif',
    fontSize: '1.4rem',
  },
  infographicTickLabelLg: {
    fontFamily: '"Helvetica","Arial", sans-serif',
    fontSize: '2.1rem',
  },
  infographicSelectionHeadingSm: {
    fontFamily: '"Arial", "Helvetica",sans-serif',
    fontSize: '1.4rem',
  },
  infographicSelectionHeadingLg: {
    fontFamily: '"Arial", "Helvetica",sans-serif',
    fontSize: '2.1rem',
  },
  tooltip: {
    ...baseTheme.typography.h3,
  },
  graphAxis: {
    ...baseTheme.typography.h3,
  },
  eyebrow: {
    fontSize: '2.1rem',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  headline: {
    fontSize: '6rem',
    fontWeight: 700,
  },
};

export default fonts;
