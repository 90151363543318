import { createMuiTheme, fade } from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';
import { macgColors, ExtensionColors } from './color';
import namedColors from './namedColors';
import buildExtensions from './extensions';

// this function returns 62.5% of the browsers font size setting
// to allow this trick to work with material ui
// https://dev.to/dan503/comment/4oo0
function getHtmlFontSize() {
  try {
    const elementToMeasure = document.createElement('div');

    elementToMeasure.style.cssText =
      'display:inline-block; padding:0; line-height:1; position:absolute; visibility:hidden; font-size:1rem';
    document.body.appendChild(elementToMeasure);
    const fontSizeString = window.getComputedStyle(elementToMeasure).fontSize;
    const fs = parseInt(fontSizeString.replace('px', ''));
    document.body.removeChild(elementToMeasure);
    return fs;
  } catch {
    // eslint-disable-next-line no-console
    console.warn('Unable to detect browser size, falling back to 10px');
    return 10;
  }
}

export function createThemeOptions(colors: ExtensionColors) {
  const baseFontStyle = {
    fontFamily: '"Helvetica", "Arial", sans-serif',
    fontSize: '1.4rem',
    color: colors.text.primary,
    fontWeight: 400,
  };

  const baseBorder = {
    borderRadius: 6,
    border: `1px solid ${colors.borderLight}`,
  };

  const propOverride: ComponentsProps = {
    MuiButton: {
      disableElevation: true,
      disableRipple: true,
    },
  };

  const baseTheme = createMuiTheme({});

  // #region Typography Override
  const typographyOverride: TypographyOptions = {
    htmlFontSize: getHtmlFontSize(),
    fontFamily: '"Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '3.6rem',
      fontWeight: 400,
    },
    h2: {
      fontSize: '2.4rem',
      fontWeight: 400,
    },
    h3: {
      fontSize: '2.1rem',
      fontWeight: 400,
    },
    h4: {
      fontSize: '1.8rem',
    },
    body1: {
      fontSize: '1.4rem',
      lineHeight: '1.6rem',
    },
    button: {
      textTransform: 'none',
      lineHeight: '1.4rem',
    },
    caption: {
      fontSize: '1.4rem',
    },
  };
  // #endregion

  // #region Palette Override
  const paletteOverride: PaletteOptions = {
    primary: {
      main: colors.primary,
      contrastText: colors.text.contrast,
    },
    background: {
      paper: '#fafafa',
      default: colors.background,
    },
    text: {
      primary: colors.text.primary,
    },
    success: {
      contrastText: colors.text.contrast,
      main: colors.primary,
    },
  };
  // #endregion

  // #region  Theme Override
  const themeOverride: Overrides = {
    MuiBackdrop: {
      root: {
        backgroundColor: fade(namedColors.alto, 0.48),
      },
    },
    MuiInput: {
      root: {
        '&::before': {
          borderBottom: 'none !important',
        },
        '&::after': {
          borderBottom: 'none !important',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '6px',
        '& $notchedOutline': {
          borderColor: colors.borderLight,
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: colors.borderDarkest,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            borderColor: colors.borderLight,
          },
        },
        '&$focused $notchedOutline': {
          borderColor: colors.borderDarkest,
          borderWidth: 1,
        },
      },
      input: {
        padding: baseTheme.spacing(1),
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '1.4rem',
      },
    },
    MuiSelect: {
      select: {
        borderRadius: 6,
        border: `1px solid ${colors.borderLight}`,
        paddingLeft: 8,
        textAlign: 'left',

        '&:focus': {
          borderRadius: 6,
          border: `1px solid ${colors.borderLight}`,
        },
      },
    },
    MuiSwitch: {
      root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
        '&$checked': {
          '&$colorSecondary': {
            transform: 'translateX(12px)',
            color: colors.borderLight,
          },
        },
      },
      switchBase: {
        padding: 2,
        color: colors.borderLight,
        '&$checked': {
          transform: 'translateX(12px)',
          '& + $track': {
            opacity: 1,
            backgroundColor: colors.background,
          },
        },
      },
      thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
        color: colors.borderLight,
      },
      track: {
        border: `1px solid ${colors.borderLight}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: colors.background,
      },

      colorSecondary: {
        '&$checked': {
          color: colors.borderLight,
        },
        '&$checked + $track': {
          backgroundColor: colors.background,
        },
      },
    },
    MuiIconButton: {
      root: {
        ...baseFontStyle,
      },
    },
    MuiButton: {
      containedPrimary: {
        fontSize: '1.6rem',
        lineHeight: '1.6rem',
        color: colors.text.contrast,
        backgroundColor: colors.primary,
        '&:hover': {
          backgroundColor: fade(colors.primary, 0.75),
          '@media (hover: none)': {
            backgroundColor: fade(colors.primary, 0.75),
          },
        },
      },
      containedSecondary: {
        ...baseBorder,
        fontSize: '1.6rem',
        lineHeight: '1.6rem',
        color: colors.text.contrast,
        backgroundColor: colors.primary,
        '&:hover': {
          backgroundColor: fade(colors.primary, 0.75),
          '@media (hover: none)': {
            backgroundColor: fade(colors.primary, 0.75),
          },
        },
      },
      outlinedPrimary: {
        ...baseBorder,
        fontSize: '1.6rem',
        lineHeight: '1.6rem',
        color: colors.primary,
        backgroundColor: colors.background,
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
      },
      outlinedSecondary: {
        ...baseBorder,
        fontSize: '1.6rem',
        lineHeight: '1.6rem',
        backgroundColor: colors.background,
        color: colors.text.primary,
        '&:hover': {
          ...baseBorder,
          border: `1px solid ${colors.borderLight}`,
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
      },
      root: {
        ...baseFontStyle,
      },
      text: {
        padding: 6,
      },
    },
    MuiTableCell: {
      head: {
        padding: 7,
        fontWeight: 'bold',
      },
      body: {
        padding: 7,
        border: 'none',
      },
    },
    MuiTablePagination: {
      toolbar: {
        background: 'inherit',
      },
    },
    MuiInputLabel: {
      root: {
        paddingLeft: baseTheme.spacing(1),
      },
      shrink: {
        paddingLeft: 0,
      },
    },
    MuiListSubheader: {
      root: {
        lineHeight: 1.4,
        fontWeight: 'bold',
        color: colors.text.primary,
        fontSize: '1.4rem',
      },
      gutters: {
        paddingLeft: baseTheme.spacing(1),
      },
    },
    MuiListItem: {
      root: {
        outline: 'none !important',
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiTabs: {
      root: {
        height: 39,
        minHeight: 39,
      },
      indicator: {
        display: 'none',
      },
      flexContainer: {
        position: 'relative',
        borderBottom: `1px solid ${colors.borderLightest}`,
      },
    },
    MuiTab: {
      root: {
        position: 'relative',
        top: 1,
        height: 38,
        minHeight: 38,
        fontSize: '2.1rem',
        padding: '6px 39px',
        '&$selected': {
          borderRadius: '16px 16px 0 0',
          border: `1px solid ${colors.borderLightest}`,
          borderBottom: `1px solid ${colors.background}`,
          fontWeight: 'bold',
        },
      },
      textColorPrimary: {
        color: colors.text.dark,
        '&.Mui-selected': {
          color: colors.primary,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.text.dark,
      },
    },
  };

  // #endregion

  return {
    props: propOverride,
    palette: paletteOverride,
    mixins: {
      toolbar: {
        minHeight: 56,
      },
    },
    typography: typographyOverride,
    overrides: themeOverride,
    extensions: buildExtensions(colors),
  };
}

export const defaultThemeOptions = createThemeOptions(macgColors);
const theme = createMuiTheme(defaultThemeOptions);
export { theme as defaultTheme };
export default theme;
