import React, { PropsWithChildren } from 'react';
import { makeStyles, capitalize, Theme, Orientation } from '@material-ui/core';
import clsx from 'clsx';
import { CloneElement } from '../common';
import { ToggleButtonProps } from './ToggleButton';
import { ButtonSize } from '../../@types';
import { ToggleIconButtonProps } from './@types';
import { IconVariant } from '../common/@types';

// Determine if the toggle button value matches, or is contained in, the
// candidate group value.
export function isValueSelected(value: any, candidate: any[]) {
  if (candidate === undefined || value === undefined) {
    return false;
  }

  if (Array.isArray(candidate)) {
    return candidate.indexOf(value) >= 0;
  }

  return value === candidate;
}

const useStyle = makeStyles((theme: Theme) => ({
  /* Styles applied to the root element. */
  root: {
    display: 'inline-flex',
    borderRadius: theme.shape.borderRadius,
  } /* Styles applied to the children. */,
  grouped: {},
  /* Styles applied to the children if `orientation="horizontal"` & `contained=true`. */
  groupedHorizontal: {
    '&:not(:first-child)': {
      marginLeft: -1,
      borderLeft: '1px solid transparent',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    '&:not(:last-child)': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  /* Styles applied to the children if `orientation="vertical"` & `contained=true`. */
  groupedVertical: {
    '&:not(:first-child)': {
      marginTop: -1,
      borderTop: '1px solid transparent',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    '&:not(:last-child)': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  /* Styles applied to the children if `orientation="horizontal"` & `contained=false`. */
  ungroupedHorizontal: {},
  /* Styles applied to the children if `orientation="vertical"` & `contained=false`. */
  ungroupedVertical: {},
}));

// TODO: Clean up the trash
type ToggleButtonGroupProps<
  TValue = any,
  TVariant extends IconVariant = 'trash'
> = {
  className?: string;
  exclusive?: boolean;
  onChange: (...args: any[]) => any;
  value?: TValue;
  orientation?: Orientation;
  size?: ButtonSize;
  children?: React.ReactElement<
    ToggleButtonProps | ToggleIconButtonProps<TValue, TVariant>
  >[];
  contained?: boolean;
};

export const ToggleButtonGroup = React.forwardRef(function ToggleButton(
  props: PropsWithChildren<ToggleButtonGroupProps>,
  ref: any
) {
  const {
    children,
    className,
    exclusive = true,
    onChange,
    value,
    orientation = 'horizontal',
    size = 'medium',
    contained = true,
    ...rest
  } = props;

  const classes = useStyle();

  const handleChange = (event: any, buttonValue: any) => {
    if (!onChange) {
      return;
    }

    const index = value && value.indexOf(buttonValue);
    let newValue;

    if (value && index >= 0) {
      newValue = value.slice();
      newValue.splice(index, 1);
    } else {
      newValue = value ? value.concat(buttonValue) : [buttonValue];
    }

    onChange(event, newValue);
  };

  const handleExclusiveChange = (event: any, buttonValue: any) => {
    if (!onChange) {
      return;
    }

    onChange(event, value === buttonValue ? null : buttonValue);
  };

  const groupedDirectionStyle =
    classes[
      `grouped${capitalize(orientation)}` as
        | 'groupedVertical'
        | 'groupedHorizontal'
    ];

  const ungroupdedDirectionStyle =
    classes[
      `ungrouped${capitalize(orientation)}` as
        | 'ungroupedVertical'
        | 'ungroupedHorizontal'
    ];

  return (
    <div
      role="group"
      className={clsx(classes.root, className)}
      ref={ref}
      {...rest}
    >
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        return (
          <CloneElement
            element={child}
            className={clsx(
              classes.grouped,
              contained ? groupedDirectionStyle : ungroupdedDirectionStyle,
              child.props.className
            )}
            onChange={exclusive ? handleExclusiveChange : handleChange}
            selected={
              child.props.selected === undefined
                ? isValueSelected(child.props.value, value)
                : child.props.selected
            }
            size={child.props.size || size}
            {...(child.props as any)}
          />
        );
      })}
    </div>
  );
});

export default ToggleButtonGroup;
