import { QueryRecord, UseQueryParams } from 'edgeco/hooks/useQueryParams';
import produce from 'immer';
import moment from 'moment';
import { IdType } from 'react-table';
import {
  REQUIRED_GROUP,
  SELECTION_GROUP,
} from 'edgeco/components/transfer-list';
import {
  ColumnDefinition,
  ColumnDefinitionList,
  DatePeriod,
  StringIndexable,
} from 'edgeco/types';
import {
  TransferItem,
  TransferListMap,
} from 'edgeco/components/transfer-list/@types';
import { ALL_FILTERS } from '../constants';
import { groupObjectsBy } from '../../../../libs/utils/utility-functions';
import {
  DateTableFilter,
  DateTableFilterOption,
  SelectTableFilter,
  TableFilterCollection,
} from '../@types';

export const mapFilterToQueryObject = (
  selectedValues: StringIndexable,
  valueKey: keyof (SelectTableFilter<string[]> | DateTableFilter) = 'value'
) =>
  Object.values(selectedValues).reduce(
    (acc, filter: SelectTableFilter<string[]> | DateTableFilter) => {
      if (filter.kind === 'date') {
        const filterValue = filter[valueKey] as DateTableFilterOption;
        acc[filter.startSearchParam || 'startDate'] = filterValue?.startDate;
        acc[filter.endSearchParam || 'endDate'] = filterValue?.endDate;
        acc[filter.field || 'datePeriod'] = filterValue?.datePeriod;
      } else {
        const searchParam = filter.searchParam!;
        if (!acc[searchParam]) acc[searchParam] = [];
        acc[searchParam] = [
          ...acc[searchParam],
          ...(filter[valueKey] as string[]),
        ];
      }
      return acc;
    },
    {}
  );

export function getFilterValuesFromQuery<T extends QueryRecord>(
  filters: TableFilterCollection,
  queryObject: UseQueryParams<T>['queryObject']
) {
  const values: any = filters.reduce((acc, filter) => {
    const id = filter.id!;
    if (filter.kind === 'group') {
      return {
        ...acc,
        ...getFilterValuesFromQuery(filter.filters, queryObject),
      };
    }

    if (filter.kind === 'date') {
      const datePeriodParam = filter.field || 'datePeriod';
      const startParam = filter.startSearchParam || 'startDate';
      const endParam = filter.endSearchParam || 'endDate';
      const timeFrame = queryObject[datePeriodParam] as DatePeriod;
      const startDate = queryObject[startParam] as Date;
      const endDate = queryObject[endParam] as Date;
      const value = {
        datePeriod: timeFrame || null,
        startDate: startDate ? moment(startDate.toISOString()) : undefined,
        endDate: endDate ? moment(endDate.toISOString()) : undefined,
      };
      acc[id] = {
        ...filter,
        value,
        editValue: { ...value },
        specificEditValue: { ...value },
      };
      return acc;
    }
    const searchParam = filter.searchParam!;
    let searchValue: any = queryObject[searchParam];
    const valueIsArray = Array.isArray(searchValue);
    if (filter.multiple) {
      if (searchValue === undefined) {
        searchValue = [];
      } else if (!valueIsArray) {
        if (searchValue === ALL_FILTERS) {
          searchValue = (filter.options as any[]).map((f) => f.value);
        } else {
          searchValue = [searchValue];
        }
      }
    } else if (valueIsArray) {
      // eslint-disable-next-line no-console
      console.error(
        'Multiple query parameters were provided ' +
          'for a filter that only accepts a single value'
      );
      [searchValue] = searchValue;
    }
    const value = searchValue === undefined ? null : searchValue;
    acc[id] = {
      ...filter,
      value,
      editValue: produce(value, (draft: any) => draft),
      specificEditValue: produce(value, (draft: any) => draft),
    };
    return acc;
  }, {} as any);
  return values;
}

function filterColumnDefinition<TData>(
  columnDefinitions: ColumnDefinitionList<TData>,
  match: (definition: ColumnDefinition) => boolean
) {
  return Object.entries(columnDefinitions).reduce<
    Partial<ColumnDefinitionList<TData>>
  >((acc, [k, value]) => {
    const def = value as ColumnDefinition;
    const key = k as keyof TData;
    if (match(def)) {
      acc[key] = def;
    }
    return acc;
  }, {});
}

export const getVisibleColumns = <TData>(
  columnDefinitions: ColumnDefinitionList<TData>
): Partial<ColumnDefinitionList<TData>> =>
  filterColumnDefinition(columnDefinitions, (c) => !c.hidden);

export const getSelectableColumns = <TData>(
  columnDefinitions: ColumnDefinitionList<TData>
) =>
  filterColumnDefinition<TData>(
    columnDefinitions,
    (c) => !c.hidden && c.group !== REQUIRED_GROUP
  );

/**
 * @param currentGroup if passed the transfer items will use this group as the current group instead of the definition group
 */
export function mapDefinitionToTransferList<TData>(
  columns: IdType<TData>[],
  columnDefinitions: Partial<ColumnDefinitionList<TData>>,
  currentGroup?: string
): TransferItem[] {
  return columns.reduce<TransferItem[]>((acc, c) => {
    const def = columnDefinitions[c as keyof TData];
    if (!def || def.hidden) return acc;
    acc.push({
      id: c,
      label: def.header,
      homeGroup: def.group,
      currentGroup: currentGroup ?? def.group,
      searchText: def.header,
    });
    return acc;
  }, []);
}

export function buildListMap<TData>(
  columnDefinitions: Partial<ColumnDefinitionList<TData>>,
  selectedColumns?: string[]
): TransferListMap {
  // Grouping right side in case an entire group is already selected.
  // Groups are used to populate left side lists.
  const initialLeft: TransferItem[] = mapDefinitionToTransferList<TData>(
    Object.keys(columnDefinitions).filter((k) => !selectedColumns?.includes(k)),
    columnDefinitions
  );

  const initialRight: TransferItem[] = selectedColumns
    ? mapDefinitionToTransferList<TData>(
        selectedColumns,
        columnDefinitions,
        SELECTION_GROUP
      )
    : [];

  const mappedRight = groupObjectsBy(
    initialRight,
    (d: TransferItem) => d.homeGroup
  ) as Map<string | number, TransferItem[]>;
  mappedRight.delete(REQUIRED_GROUP);

  const mappedLeft = groupObjectsBy(
    initialLeft,
    (d: TransferItem) => d.homeGroup
  ) as Map<string | number, TransferItem[]>;
  mappedLeft.delete(REQUIRED_GROUP);

  const newMap = new Map(mappedLeft);
  if (selectedColumns) {
    newMap.set(SELECTION_GROUP, [...mappedRight.values()].flat());
  }
  return newMap;
}
