/* eslint-disable no-param-reassign */
import { cloneElement, useCallback } from 'react';
import clsx from 'clsx';
import { StringIndexable } from 'edgeco/types';

// TODO: type arguments & props
interface CloneElementProps {
  element: React.ReactElement;
  children?: any;
  childRef?: any;
}

/**
 * CloneElement is a wrapper component for createElement function.
 * This allows you to describe your cloning element declaratively
 * which is a more natural API for React.
 */
export function CloneElement<T = StringIndexable>({
  children,
  element,
  childRef,
  ...rest
}: CloneElementProps & Partial<T>): React.ReactElement {
  const getProjectedProps = useCallback(
    (props: StringIndexable) => {
      const childProps = element.props;

      return Object.keys(props).reduce((acc, key: string) => {
        const prop = props[key];
        const childProp = childProps[key];

        if (typeof prop === 'function' && typeof childProp === 'function') {
          acc[key] = (args: any) => {
            prop(args);
            childProp(args);
          };
        } else if (key === 'className') {
          acc[key] = clsx(prop, childProp);
        } else {
          acc[key] = prop;
        }

        return acc;
      }, {} as StringIndexable);
    },
    [element]
  );

  if (element === null) {
    return children;
  }

  // Tricky logic around functional vs class components
  const ref = childRef
    ? (node: any) => {
        if (typeof childRef === 'function') {
          childRef(node);
        } else if (ref) {
          childRef.current = node;
        }
      }
    : undefined;

  const newProps = getProjectedProps(rest);
  return cloneElement(element, {
    ...element.props,
    ...newProps,
    children,
    ref,
  });
}

export default CloneElement;
