import { useColor } from 'edgeco/hooks';
import { CartesianGrid } from 'recharts';
import { GridProps } from '../@types';
import { renderChartWrapper } from './renderChartWrapper';

function Grid({ vertical, stroke, ...rest }: GridProps) {
  const color = useColor();
  return (
    <CartesianGrid
      vertical={vertical ?? false}
      stroke={stroke ?? color.borderLight}
      {...rest}
    />
  );
}

const WrappedGrid = renderChartWrapper(CartesianGrid, Grid, {
  vertical: undefined,
  stroke: undefined,
});

export { WrappedGrid as Grid };
