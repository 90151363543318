import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Row, SubComponent, TableRowProps } from 'react-table';
import clsx from 'clsx';
import CollapsibleRow from './CollapsibleRow';
import TableCell from './TableCell';
import { getShadowGradient } from './tableUtils';

// 35 characters per line
const useStyles = makeStyles((theme) => ({
  row: {
    position: 'relative',
    gridColumn: '1 / -1',
  },
  alt: {
    background: theme.extensions.color.tableAltRow,
  },
  shadowTop: getShadowGradient('bottom'),
  shadowBottom: getShadowGradient('top'),
}));

type Props<D extends object> = {
  row: Row<D>;
  rowProps: TableRowProps;
  columnCount: number;
  subComponent?: SubComponent<D>;
  hasHorizontalScroll?: boolean;
};

function TableRow<D extends object>({
  row,
  subComponent,
  hasHorizontalScroll,
}: Props<D>) {
  const classes = useStyles();
  const renderedRow = useMemo(() => row, [row]); // if columns are different, re-render cells

  return (
    <React.Fragment>
      {renderedRow.cells.map((cell, index) => (
        <TableCell
          key={`row-${row.id}-cell-${index}`}
          className={clsx(row.index % 2 !== 0 && classes.alt)}
          cell={cell}
          hasHorizontalScroll={hasHorizontalScroll}
        />
      ))}
      {renderedRow.isExpanded && (
        <div className={clsx(classes.row)}>
          <div className={classes.shadowTop} />
        </div>
      )}
      <CollapsibleRow row={row} subComponent={subComponent} />
      {renderedRow.isExpanded && (
        <div className={clsx(classes.row)}>
          <div className={classes.shadowBottom} />
        </div>
      )}
    </React.Fragment>
  );
}

export default TableRow;
