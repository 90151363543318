import ResizeObserver from 'resize-observer-polyfill';
import { useCallback, useEffect, useState } from 'react';
import { Scrollable } from 'edgeco/types';

type BoxSize = {
  inlineSize: number;
  blockSize: number;
};

type Measurement = {
  scroll: Scrollable;
  borderBoxSize: BoxSize;
  contentRect: {
    x: number;
    y: number;
    width: number;
    height: number;
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
};

function getStyleValue(element: Element, property: string) {
  const style = document?.defaultView?.getComputedStyle(element);
  if (style) {
    return style.getPropertyValue(property);
  }
  return '';
}

function getPxCssValue(element: Element, property: string) {
  const style = document?.defaultView?.getComputedStyle(element);
  if (style) {
    const strValue = style.getPropertyValue(property);
    return parseInt(strValue.replace('px', ''));
  }
  return 0;
}

export function useMeasure(
  onResize?: (resizeEntry: ResizeObserverEntry) => void
) {
  const [element, setElement] = useState<any>();
  const [entry, setEntry] = useState<Measurement>({
    borderBoxSize: {
      inlineSize: 0,
      blockSize: 0,
    },
    contentRect: {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    scroll: {
      horizontal: false,
      vertical: false,
    },
  });

  // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/borderBoxSize
  // TODO: update to just use the borderBoxSize on ResizeObserverEntry once it is no longer experimental
  useEffect(() => {
    let ro: ResizeObserver;
    if (element) {
      ro = new ResizeObserver((entries) => {
        entries.forEach((currentEntry) => {
          onResize?.(currentEntry);
          let inlineSize: number;
          let blockSize: number;
          const { target } = currentEntry;
          blockSize = getPxCssValue(target, 'height');
          inlineSize = getPxCssValue(target, 'width');
          if (getStyleValue(target, 'box-sizing') === 'content-box') {
            blockSize +=
              getPxCssValue(target, 'padding-top') +
              getPxCssValue(target, 'padding-bottom') +
              getPxCssValue(target, 'border-top-width') +
              getPxCssValue(target, 'border-bottom-width');
            inlineSize +=
              getPxCssValue(target, 'padding-left') +
              getPxCssValue(target, 'padding-right') +
              getPxCssValue(target, 'border-left-width') +
              getPxCssValue(target, 'border-right-width');
          }
          // We can't just use the entry, it uses getters that are lost out of scope, spreading also doesn't work.
          setEntry({
            contentRect: currentEntry.contentRect,
            borderBoxSize: {
              blockSize,
              inlineSize,
            },
            scroll: {
              horizontal: target.scrollWidth > target.clientWidth,
              vertical: target.scrollHeight > target.clientHeight,
            },
          });
        });
      });
      ro.observe(element);
    }
    return () => {
      ro?.unobserve(element);
      ro?.disconnect();
    };
  }, [element, onResize]);

  const ref = useCallback((elem) => {
    setElement(elem);
  }, []);
  return { ref, measurement: entry };
}
