import { SvgIcon, SvgIconProps } from '@material-ui/core';

import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  ChevronLeftSharp,
  ChevronRightSharp,
  CheckBoxOutlineBlankSharp,
  CheckSharp,
  Cached,
} from '@material-ui/icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import {
  faTrashAlt as faSolidTrashAlt,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { useColor } from 'edgeco/hooks';
import LayeredIcon from './LayeredIcon';
import { CustomIconProps, IconVariant } from './@types';

export function Icon<TVariant extends IconVariant>({
  iconProps,
  variant,
  primaryColor = 'currentColor',
  secondaryColor,
  backgroundColor: backgroundColorProp,
}: CustomIconProps<TVariant>) {
  const colors = useColor();
  const backgroundColor = backgroundColorProp ?? colors.background;
  const svgProps = iconProps as SvgIconProps;
  const faProps = iconProps as FontAwesomeIconProps;

  const renderedPrimaryStyle = {
    ...svgProps?.style,
    fill: primaryColor,
  };
  const backgroundRect = (
    <SvgIcon {...svgProps}>
      <rect
        strokeWidth="0"
        fill={backgroundColor}
        width="18"
        height="18"
        x="3"
        y="3"
      />
    </SvgIcon>
  );
  const checkbox = (
    <LayeredIcon>
      {backgroundRect}
      <CheckBoxOutlineBlankSharp style={renderedPrimaryStyle} {...svgProps} />
    </LayeredIcon>
  );
  const checkboxChecked = (
    <LayeredIcon>
      {checkbox}
      <CheckSharp
        {...svgProps}
        style={{
          ...svgProps?.style,
          transform: 'scale(0.75, 0.75)',
          fill: secondaryColor,
        }}
      />
    </LayeredIcon>
  );
  const close = (
    <SvgIcon
      viewBox="0 0 21 19"
      style={{
        ...renderedPrimaryStyle,
        height: '2.1rem',
        width: '1.9rem',
        border: `2px solid ${
          renderedPrimaryStyle.fill
            ? renderedPrimaryStyle.fill
            : colors.borderLight
        }`,
        color: renderedPrimaryStyle.fill
          ? renderedPrimaryStyle.fill
          : colors.borderLight,
        fontSize: '3.2rem',
      }}
      className={svgProps?.className}
    >
      <text x="0" y="21">
        X
      </text>
    </SvgIcon>
  );
  const trash = (
    <FontAwesomeIcon
      {...faProps}
      color={primaryColor}
      icon={faSolidTrashAlt as any}
    />
  );
  const trashAlt = (
    <FontAwesomeIcon
      {...faProps}
      color={primaryColor}
      icon={faTrashAlt as any}
    />
  );
  const warning = (
    <FontAwesomeIcon
      {...faProps}
      color={primaryColor}
      icon={faExclamationTriangle as any}
    />
  );
  const chevronUp = (
    <KeyboardArrowUp
      style={{ fontSize: '2.8rem', ...renderedPrimaryStyle }}
      {...svgProps}
    />
  );
  const chevronDown = (
    <KeyboardArrowDown
      style={{ fontSize: '2.8rem', ...renderedPrimaryStyle }}
      {...svgProps}
    />
  );

  const chevronLeft = (
    <ChevronLeftSharp style={renderedPrimaryStyle} {...svgProps} />
  );

  const chevronRight = (
    <ChevronRightSharp style={renderedPrimaryStyle} {...svgProps} />
  );

  const cached = <Cached style={renderedPrimaryStyle} {...svgProps} />;

  const renderedVariant = variant
    ? {
        close,
        trash,
        'trash-alt': trashAlt,
        chevronDown,
        chevronUp,
        chevronLeft,
        chevronRight,
        checkbox,
        checkboxChecked,
        cached,
        warning,
      }[variant]
    : null;

  return renderedVariant;
}

export default Icon;
