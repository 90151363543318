import React, { PropsWithChildren } from 'react';
import { makeStyles, Theme } from '@material-ui/core';

export function getContentStyle(theme: Theme, props: any = {}) {
  const { contentStyle = {} } = props;
  const {
    spacing,
    extensions: { color, boxShadow },
  } = theme;
  return {
    border: `1px solid ${color.borderLight}`,
    borderRadius: '8px',
    boxShadow,
    boxSizing: 'border-box',
    padding: spacing(1),
    width: props.width,
    height: props.height,
    '& a': {
      color: color.text.primary,
    },
    backgroundColor: color.background,
    ...contentStyle,
  };
}

const useStyles = makeStyles((theme) => ({
  root: (props) => getContentStyle(theme, props),
}));

export function TooltipContent(props: PropsWithChildren<any>) {
  const { children, ...rest } = props;
  const classes = useStyles(rest);
  return <div className={classes.root}>{children}</div>;
}

export default TooltipContent;
