import { Navigate, Outlet } from 'react-router';
import FeatureFlag from './FeatureFlag';
import { DefaultFeature } from './FeatureFlagContext';

type FeatureRouteProps<TFeatureTypes extends string = DefaultFeature> = {
  feature: TFeatureTypes;
};

export const FeatureRoute = <TFeatureTypes extends string = DefaultFeature>({
  feature,
}: FeatureRouteProps<TFeatureTypes>) => (
  <>
    <FeatureFlag name={feature}>
      <Outlet />
    </FeatureFlag>

    <FeatureFlag name={feature} showIfDisabled>
      <Navigate to="/error/404" />
    </FeatureFlag>
  </>
);
