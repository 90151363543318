import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TextField,
  InputAdornment,
  InputProps,
  TextFieldProps,
  makeStyles,
  Theme,
  fade,
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(({ spacing, extensions: { color } }: Theme) => ({
  inputStyle: {
    backgroundColor: color.background,
    paddingLeft: spacing(1),
    borderRadius: 6,
    fontSize: '1.6rem',
    border: `1px  solid ${color.lightGrayBorder}`,
  },
  adornmentStyle: {
    color: fade(color.borderDarkest, 0.46),
  },
}));

export type SearchBoxProps = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputProps?: InputProps;
  textFieldProps?: TextFieldProps;
};

export function SearchBox({
  handleChange,
  inputProps,
  textFieldProps,
}: SearchBoxProps) {
  const { inputStyle, adornmentStyle } = useStyles();
  return (
    <TextField
      margin="dense"
      variant="outlined"
      onChange={handleChange}
      placeholder="Search"
      InputProps={{
        className: clsx(inputStyle, inputProps?.className),
        startAdornment: inputProps?.startAdornment ?? (
          <InputAdornment position="start">
            <FontAwesomeIcon
              flip="horizontal"
              className={adornmentStyle}
              icon={faSearch}
              size="1x"
            />
          </InputAdornment>
        ),
        ...inputProps,
      }}
      {...textFieldProps}
    />
  );
}

export default SearchBox;
