import React from 'react';

/**
 * Creates a getter for a React reference of an instance of an object type (class, component, etc). Can be used to avoid rerenders.
 * @param obj Object to create a reference for.
 */
export function useGetLatest<TInstance>(obj: TInstance) {
  const ref = React.useRef<TInstance>(obj);
  const instance = React.useCallback(() => ref.current, []);
  return instance;
}
