import DebouncePromise from 'debounce-promise';
import { resolveLastPromise } from '../utils/utility-functions';
import useConstant from './useConstant';

/**
 *
 * @param func Async function to wrap and debounce.  Only returning the last called value within the delay
 * @param delayInMs Delay to wait before resolving the async promise
 * @param options
 */

export const useDebouncedPromise = <
  TFunction extends (...args: any[]) => ReturnType<TFunction>
>(
  func: TFunction,
  delayInMs: number,
  options = {}
) => {
  // resolveLastPromise is stateful, make sure it's not created multiple times
  const promiseRef = useConstant(() => {
    // Debounce the function
    const debouncedFn = DebouncePromise(func, delayInMs, options);

    // Make sure we only return the most recent call
    const delayResolved = resolveLastPromise(debouncedFn);

    // Actually create the new function returning the promise
    return (...args: Parameters<TFunction>) => delayResolved(...args);
  });
  return promiseRef;
};

export default useDebouncedPromise;
