import React from 'react';
import {
  makeStyles,
  Theme,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import clsx from 'clsx';

import { ListItemAction, ListItemProps } from './@types';

(MuiListItem as any).displayName = 'MuiListItem';

// TODO: change round & narrow to custom variants.  Should not be able to do both at the same time
const useStyles = makeStyles<Theme>(
  ({ spacing, extensions: { color } }: Theme) => ({
    narrowWrapper: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    roundWrapper: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    roundedStyle: {
      padding: ' 4px 8px 4px 8px',
      width: '100%',
      border: '1px solid',
      borderRadius: spacing(1),
      borderColor: color.borderLight,
      margin: spacing(1 / 2, 1),
      backgroundColor: color.background,
    },
  })
);

export const ListItem = React.forwardRef<HTMLLIElement, ListItemProps>(
  function ListItem(
    {
      isNarrow = true,
      isRounded,
      children,
      primaryAction,
      secondaryAction,
      button,
      text,
      className,
      ...rest
    }: ListItemProps,
    forwardedRef
  ) {
    const { roundedStyle, narrowWrapper, roundWrapper } = useStyles();
    const renderAction = ({
      children: actionChildren,
      iconButton,
    }: ListItemAction) => actionChildren ?? iconButton;
    const primaryText =
      typeof text?.primary === 'function' ? text?.primary() : text?.primary;
    return (
      <MuiListItem
        button={button as any} // reason for any cast: https://github.com/mui-org/material-ui/issues/14971
        className={clsx(className, {
          [narrowWrapper]: isNarrow || isRounded,
          [roundWrapper]: isRounded,
        })}
        ref={forwardedRef}
        {...rest}
      >
        {children ?? (
          <>
            {primaryAction && (
              <ListItemIcon {...primaryAction.props}>
                {renderAction(primaryAction)}
              </ListItemIcon>
            )}
            <div
              className={clsx('foo', {
                [roundedStyle]: isRounded,
              })}
            >
              <ListItemText
                primary={primaryText}
                secondary={text?.secondary}
                {...text?.props}
              />
              {secondaryAction && (
                <ListItemSecondaryAction {...secondaryAction.props}>
                  {renderAction(secondaryAction)}
                </ListItemSecondaryAction>
              )}
            </div>
          </>
        )}
      </MuiListItem>
    );
  }
);

export default ListItem;
