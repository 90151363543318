import { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import numeral from 'numeral';

const useStyles = makeStyles({
  total: {
    margin: 'calc(50% - 25px) 0 10px',
    textAlign: 'center',
    fontSize: '2.4rem',
  },
  totalLabel: {
    margin: '0 15px',
    textAlign: 'center',
    fontSize: '1.2rem',
  },
});

type DonutChartTotalProps = {
  total: number;
  description?: string;
  format?: string;
  abbreviationThreshold?: number;
};

export function DonutChartTotal({
  total,
  description,
  format = '$0,0',
  abbreviationThreshold = 10000000,
}: DonutChartTotalProps) {
  const classes = useStyles();

  const formattedTotal = useMemo(() => {
    const formatString = total < abbreviationThreshold ? format : '$0.0 a';
    return numeral(total).format(formatString).toUpperCase();
  }, [total, format, abbreviationThreshold]);

  return (
    <>
      <div className={classes.total}>{formattedTotal}</div>
      <div className={classes.totalLabel}>{description}</div>
    </>
  );
}

export default DonutChartTotal;
