import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import { withTooltip } from '../hooks/withTooltip';

type Props = {
  x?: number;
  y?: number;
  payload?: any;
  width?: number;
  labelWidth?: number;
};

type ClassKey = 'label';
const styles = () =>
  createStyles<ClassKey, Props>({
    label: (props) => ({
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: props.labelWidth,
      whiteSpace: 'nowrap',
      textAlign: 'center',
    }),
  });

type StyledProps = WithStyles<ClassKey> & Props;

const EllipsisLabel = React.forwardRef<HTMLDivElement, StyledProps>(
  ({ payload, classes }, ref) => {
    return (
      <div ref={ref} className={classes.label}>
        {payload?.value}
      </div>
    );
  }
);

function TooltipAxisTick(props: StyledProps) {
  const { x, y, payload, labelWidth = 90 } = props;
  const TooltipLabel = withTooltip(EllipsisLabel);
  return (
    <g transform={`translate(${x!},${y})`}>
      <foreignObject y={0} x={-labelWidth / 2} width={labelWidth} height="30">
        <TooltipLabel
          {...{ ...props, labelWidth }}
          tooltipOptions={{ content: <div>{payload?.value}</div> }}
        />
      </foreignObject>
    </g>
  );
}

const Styled = withStyles(styles)(TooltipAxisTick);
export { Styled as TooltipAxisTick };
export default Styled;
