import {
  createNamedContext,
  useGenericContext,
  useGenericCreateContext,
} from 'edgeco/hooks/genericContext';

export enum DefaultFeature {
  ProfilePage = 'profile-page',
  Future1Page = 'future1-page',
  Notifications = 'notifications',
}

type FeatureFlagContextType<FeatureNames extends string = DefaultFeature> =
  Record<FeatureNames, boolean>;

// FeatureFlagContext should never be exported, it is not type safe and shouldn't be used directly
const FeatureFlagContext = createNamedContext('FeatureFlagContext', {});

export const useCreateFeatureFlagContext = <T extends string = DefaultFeature>(
  contextValues: FeatureFlagContextType<T>
) => {
  return useGenericCreateContext(contextValues, FeatureFlagContext);
};

export const useFeatureFlagContext = <T extends string = DefaultFeature>() =>
  useGenericContext<FeatureFlagContextType<T>>(FeatureFlagContext);
