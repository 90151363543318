import { List, makeStyles } from '@material-ui/core';

import {
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  Draggable,
  DraggableRubric,
} from 'react-beautiful-dnd';
import { TransferItem } from '../transfer-list/@types';
import {
  DraggableListItemProps,
  DraggableListProps,
  ListItemProps,
} from './@types';

import DraggableListItem from './DraggableListItem';

const useStyles = makeStyles({
  listStyle: {
    width: '100%',
  },
});

const getRenderListItem =
  (
    listItems: TransferItem[],
    renderListItem: ListItemProps | ((item: TransferItem) => ListItemProps)
  ) =>
  (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    rubric: DraggableRubric
  ) => {
    const transferItem = listItems[rubric.source.index];
    const renderedProps =
      typeof renderListItem === 'function'
        ? renderListItem(transferItem)
        : renderListItem;
    return (
      <DraggableListItem
        key={transferItem.id}
        provided={provided}
        isDragging={snapshot.isDragging}
        transferItem={transferItem}
        {...renderedProps}
      />
    );
  };

function DraggableList(props: DraggableListProps) {
  const { listItems, listId, listProps, renderListItem } = props;
  const { listStyle } = useStyles();
  const renderItem = getRenderListItem(listItems, renderListItem);

  return (
    <Droppable renderClone={renderItem} droppableId={listId.toString()}>
      {(provided) => (
        <List
          className={listStyle}
          ref={provided.innerRef}
          {...provided.droppableProps}
          {...listProps}
        >
          {listItems.map((item, idx) => (
            <Draggable key={item.id} draggableId={item.id} index={idx}>
              {renderItem}
            </Draggable>
          ))}
          {provided.placeholder}
        </List>
      )}
    </Droppable>
  );
}

export default DraggableList;
