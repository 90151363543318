import { Box } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  value?: number;
  index: number;
  tabName: string;
}>;

export function TabPanel({ children, value, index, tabName }: Props) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${tabName}-tabpanel`}
      aria-labelledby={`${tabName}-tab`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default TabPanel;
