import React, { useState } from 'react';

import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import { OptionalClasses } from 'edgeco/types/material-ui';

const styles = () =>
  createStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
  });

export type MobileDrawerClassKey = keyof ReturnType<typeof styles>;
export type MobileDrawerProps = {
  entries: string[];
  onChange: (selection: number) => void;
  direction: 'left' | 'right' | 'top' | 'bottom';
  classes: OptionalClasses<MobileDrawerClassKey>;
};

function MobileDrawer({
  entries,
  onChange,
  direction,
  classes,
}: WithStyles<MobileDrawerClassKey> & MobileDrawerProps) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setDrawerOpen(open);
    };

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {entries.map((text, index) => (
          <ListItem button key={text} onClick={() => onChange(index)}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <React.Fragment>
        <IconButton onClick={toggleDrawer(true)}>
          <MenuOutlinedIcon />
        </IconButton>
        <SwipeableDrawer
          anchor={direction}
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}

const Styled = withStyles(styles)(MobileDrawer);
export { Styled as MobileDrawer };
export default Styled;
