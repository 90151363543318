import { TypedFunction } from 'edgeco/types';

export class Event {
  name: any;

  callbacks: TypedFunction<any[]>[];

  constructor(name: string) {
    this.name = name;
    this.callbacks = [];
  }

  addHandler(cb: TypedFunction<any[]>) {
    this.callbacks.push(cb);
  }

  removeHandler(cb: TypedFunction<any[]>) {
    const idx = this.callbacks.findIndex((item) => item === cb);
    if (idx >= 0) {
      this.callbacks.splice(idx, 1);
    }
  }

  raise(...args: any[]) {
    // eslint-disable-next-line no-console
    for (let i = 0; i < this.callbacks.length; i++) {
      this.callbacks[i](...args);
    }
  }
}

export default Event;
