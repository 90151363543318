import React, { useCallback, useMemo, useState } from 'react';
import {
  Column,
  IdType,
  PluginHook,
  useColumnOrder,
  usePagination,
  useSortBy,
  useTable as useReactTable,
  TableState,
  SubComponentProps,
} from 'react-table';

import { useSticky } from 'react-table-sticky';
import { ColumnDefinitionList, RecordSet } from 'edgeco/types';
import { mapTableColumns } from './tableUtils';
import { TABLE_EXPANDER_ID } from './constants';
import ExpanderCell from './ExpanderCell';
import { useExpanded } from './plugins/useExpanded';

const withSubComponent = <TData extends object>(columns: Column<TData>[]) => {
  return [
    {
      Header: () => null,
      id: TABLE_EXPANDER_ID,
      Cell: ExpanderCell,
      width: 58,
      getProps: () => ({ sticky: 'left' }),
      sticky: 'left',
    } as Column<TData>,
    ...columns,
  ];
};
type UseTableOptions<TData extends object> = {
  columns: (keyof TData)[];
  columnDefinitions: ColumnDefinitionList<TData>;
  initialSelectedColumns: IdType<TData>[];
  initialState?: Partial<TableState<TData>>;
  subComponent?: (props: SubComponentProps<TData>) => React.ReactElement;
};

export function useTable<TData extends object = {}>({
  columns,
  columnDefinitions,
  initialSelectedColumns,
  initialState,
  subComponent,
}: UseTableOptions<TData>) {
  // There's a bug if the initial state is set to undefined.  Throws state cannot change on an unmounted component.  Even if the actual instance data is set to a blank array
  const [tableData, setTableData] = useState<TData[]>();
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);

  const updateTableData = useCallback((data?: RecordSet<TData[]>) => {
    const { page, records } = data || {};
    if (page) {
      setPageCount(page.pageCount);
    }
    setTableData(records);
  }, []);

  const defaultTableState: TableState<TData> = {
    pageSize: 40,
    pageIndex: 0,
    columnOrder: [] as string[],
    expanded: {} as Record<IdType<TData>, boolean>,
    previouslyExpanded: {} as Record<IdType<TData>, boolean>,
    sortBy: [],
  };

  const plugins: Array<PluginHook<TData>> = useMemo(() => {
    const renderedPlugins: Array<PluginHook<TData>> = [
      useColumnOrder,
      useSortBy,
      useSticky,
    ];
    // usePagination must be entered after useExpanded
    if (subComponent) {
      renderedPlugins.push(useExpanded);
    }
    renderedPlugins.push(usePagination);
    return renderedPlugins;
  }, [subComponent]);

  const mappedColumns = useMemo<Array<Column<TData>>>(() => {
    const mapped = mapTableColumns(columns, columnDefinitions);

    return subComponent ? withSubComponent(mapped) : mapped;
  }, [columnDefinitions, columns, subComponent]);
  const tableInstance = useReactTable(
    {
      columns: mappedColumns,
      data: useMemo(() => tableData || [], [tableData]),
      initialState: { ...defaultTableState, ...initialState },
      columnDefinitions,
      initialSelectedColumns,
      initialHiddenColumns: initialState?.hiddenColumns ?? [],
      manualPagination: true,
      manualSortBy: true,
      autoResetSortBy: false,
      autoResetPage: false,
      autoResetExpanded: false,
      pageCount,
      useControlledState: (state) => {
        return {
          ...state,
          loading,
        };
      },
    },
    ...plugins
  );

  return {
    updateTableData,
    tableInstance,
    tableData,
    setTableData,
    pageCount,
    setPageCount,
    subComponent,
    setLoading,
  };
}

export default useTable;
