import React from 'react';
import { AccordionContextType } from './@types';

export const AccordionContext =
  React.createContext<AccordionContextType | null>(
    null
  ) as React.Context<AccordionContextType>;

AccordionContext.displayName = 'AccordionContext';
(AccordionContext.Provider as any).displayName = 'AccordionContext.Provider';
(AccordionContext.Consumer as any).displayName = 'AccordionContext.Consumer';

export default AccordionContext;
