import { Tabs, Tab, TabsProps } from '@material-ui/core';
import { SetState } from 'edgeco/types/uncontrollable';
import React, { PropsWithChildren, useMemo } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useUncontrolled } from 'uncontrollable';
import MobileDrawer, { MobileDrawerProps } from './MobileDrawer';

type TabConfig = {
  title: string;
  content: React.ReactNode | undefined;
};

type Props = PropsWithChildren<{
  tabs: TabConfig[];
  MobileDrawerProps?: Partial<MobileDrawerProps>;
  TabsProps?: Partial<TabsProps>;
  selectedIndex?: number;
  onSelectedIndexChanged?: SetState<number>;
}>;

export const MobileTabsMenu = (props: Props) => {
  const { tabs } = props;

  const controlledProps = useUncontrolled(props, {
    selectedIndex: 'onSelectedIndexChanged',
  });

  const { selectedIndex = 0, onSelectedIndexChanged } = controlledProps;
  const titles = useMemo(() => tabs.map((t) => t.title), [tabs]);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    onSelectedIndexChanged?.(newValue);
  };
  return (
    <>
      {isMobile && !isTablet ? (
        <MobileDrawer
          direction="left"
          entries={titles}
          onChange={onSelectedIndexChanged!}
          {...props.MobileDrawerProps}
        />
      ) : (
        <Tabs
          value={selectedIndex}
          textColor="primary"
          onChange={handleChange}
          {...props.TabsProps}
        >
          {tabs?.map((t, index) => (
            <Tab key={t.title} id={index.toString()} label={t.title} />
          ))}
        </Tabs>
      )}
      {tabs[selectedIndex]?.content}
    </>
  );
};

export default MobileTabsMenu;
