import { WithStyles } from '@material-ui/styles';
import { ListItemAction } from '../@types';

import ListItem from '../ListItem';
import { AccordionClassKey, AccordionItemProps } from './@types';

export function AccordionItem({
  item,
  group,
  Actions,
  classes,
}: WithStyles<AccordionClassKey> & AccordionItemProps) {
  let action: ListItemAction | undefined;
  if (Actions) {
    action = {
      iconButton: <Actions item={item} group={group}></Actions>,
    };
  }
  return (
    <ListItem
      className={classes.item}
      isRounded
      isNarrow
      text={{ primary: item }}
      secondaryAction={action}
    />
  );
}
export default AccordionItem;
