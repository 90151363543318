// In general, withTooltip should be preferred over using the built in rechart tooltips,
// If you're unable to use the HOC due to how recharts renders, as is the case with Donut/Pie
// charts, then this can be used as an alternative.
import React, { PropsWithChildren, useEffect } from 'react';
import { makeStyles, useTheme, Theme } from '@material-ui/core';
import { Tooltip, TooltipProps } from 'recharts';
import clsx from 'clsx';

import { getContentStyle } from './common/TooltipContent';
import { ActiveState, TooltipRenderer } from './@types';

const useStyles = makeStyles<Theme, TooltipWrapperProps>((theme) => ({
  wrapper: {
    '&$active': {
      '& .recharts-tooltip-wrapper': {
        // not ideal but recharts doesn't provide a good alternative
        visibility: 'visible !important',
      },
    },
    '& .recharts-tooltip-wrapper': {
      zIndex: theme.zIndex.tooltip,
    },
  },
  active: {},
}));

export type TooltipWrapperProps = PropsWithChildren<
  Omit<TooltipProps, 'content'>
> & {
  activeState: ActiveState;
  width?: number;
  height?: number;
  content: TooltipRenderer;
};

function ControlledTooltipWrapper(props: TooltipWrapperProps) {
  const { coordinate, activeState, content } = props;
  const {
    onMouseEnter,
    onMouseLeave,
    onPositionChange,
    contentProps: payload,
  } = activeState.tooltip;
  const x = coordinate?.x;
  const y = coordinate?.y;
  const theme = useTheme();
  const classes = useStyles({ ...props });

  const containerProps: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > = {
    onMouseEnter,
    onMouseLeave,
  };

  // Pass the position calculated by recharts up to our state management hook
  useEffect(() => {
    if (onPositionChange && x !== undefined && y !== undefined) {
      onPositionChange(x, y);
    }
  }, [onPositionChange, x, y]);

  return (
    <div
      {...containerProps}
      className={clsx(classes.wrapper, {
        [classes.active]: activeState.tooltip.active,
      })}
    >
      <Tooltip
        {...props}
        content={() => (payload ? content(payload) : null)}
        contentStyle={getContentStyle(theme, props)}
        coordinate={activeState.tooltip.coordinate}
        active={activeState.tooltip.active}
        wrapperStyle={
          activeState.isClickable ? { pointerEvents: 'initial' } : undefined
        }
      />
    </div>
  );
}

// displayName is used by recharts to determine the type of the component
// without this the tooltip will not display
ControlledTooltipWrapper.displayName = 'Tooltip';

export default ControlledTooltipWrapper;
