import { PropsWithChildren } from 'react';
import {
  makeStyles,
  Button as MuiButton,
  ButtonProps,
} from '@material-ui/core';
import clsx from 'clsx';

const useStyle = makeStyles(({ spacing, extensions: { color } }) => ({
  root: {
    fontSize: '1.8rem',
    fontWeight: 400,
    lineHeight: '2.1rem',
    padding: spacing(1) + 2,
    borderRadius: '6px',
    borderColor: color.borderLight,
  },
}));

type ButtonWrapperProps = ButtonProps;

export function Button({
  className,
  children,
  ...rest
}: PropsWithChildren<ButtonWrapperProps>) {
  const classes = useStyle();
  return (
    <MuiButton className={clsx(classes.root, className)} {...rest}>
      {children}
    </MuiButton>
  );
}

export default Button;
