import React from 'react';
import { DataManagerProps } from './@types';

import { XAxis, YAxis } from './common';

/**
 * Renders chart with state and state modification methods
 * @param Component Chart or Table wrapper component
 */
export function withDataProvider<P>(Component: React.ComponentType<P>) {
  return ({ graphItems, chart, chartOptions }: DataManagerProps<P>) => {
    const getChartProps = () => {
      const { barGap, renderYAxis, yAxis, xAxis, ...rest } = chartOptions || {};
      const yAxisProps = renderYAxis && renderYAxis(chart.chartData);
      return {
        ...rest,
        yAxis: <YAxis {...yAxisProps} {...yAxis} />,
        xAxis: <XAxis {...xAxis} />,
        dataShape: chart,
        barGap,
      };
    };
    const renderedComponent = () => (
      <Component {...(getChartProps() as any)}>{graphItems}</Component>
    );

    return {
      renderData: renderedComponent(),
    };
  };
}
