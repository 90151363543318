import {
  SearchParams,
  StringifyParams,
} from 'edgeco/graphql/advisor-summary/@types/filter-specifications';
import { useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router';
import {
  appendSearchValue,
  convertQueryToValue,
  setSearchValue,
} from '../utils/router-utils';

export type QueryParamValue = number | string | string[] | Date | undefined;
export type QueryRecord = Record<
  string,
  number | string | string[] | Date | undefined
>;
export type UseQueryParams<TType extends QueryRecord> = {
  currentQuery: SearchParams<StringifyParams<TType>>;
  modifyQuery: (...params: [keyof TType, TType[keyof TType]][]) => void;
  modifyAndAppendQuery: (
    ...params: [keyof TType, TType[keyof TType]][]
  ) => void;
  getParam: <K extends keyof TType, V extends TType[K]>(
    key: K
  ) => V | undefined;
  queryObject: Partial<TType>;
};
/**
 * Provides typed url query parameters from React Router (i.e. ?p1=Param1&p2=Param2), with helper methods to update them
 */
export const useQueryParams = <
  TType extends Record<
    string,
    number | string | string[] | Date | undefined
  > = {}
>(): UseQueryParams<TType> => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentQuery = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const updateHistory = useCallback(
    (query: URLSearchParams) => {
      navigate({
        pathname: location.pathname,
        search: `?${query.toString()}`,
      });
    },
    [navigate, location.pathname]
  );

  /**
   * Takes a tuple of key value pairs and updates the current query string, based on the provided values.
   * Appends values if key exists.
   */
  const modifyAndAppendQuery = useCallback(
    (...params: [keyof TType, TType[keyof TType]][]) => {
      const updated = params.reduce(
        appendSearchValue,
        new URLSearchParams(currentQuery)
      );
      updateHistory(updated);
    },
    [currentQuery, updateHistory]
  );

  /**
   * Takes a tuple of key value pairs and replaces or adds the current query value, based on the provided values.
   * Removes undefined values
   */
  const modifyQuery = useCallback(
    (...params: [keyof TType, TType[keyof TType]][]) => {
      const updated = params.reduce(
        setSearchValue,
        new URLSearchParams(currentQuery)
      );
      updateHistory(updated);
    },
    [currentQuery, updateHistory]
  );

  /**
   * Returns the query value to the converted type value
   */
  const getParam = useCallback(
    <K extends keyof TType, V extends TType[K]>(key: K): V | undefined => {
      const value = currentQuery.getAll(key.toString());
      if (value === undefined || value === null) {
        return undefined;
      }
      return convertQueryToValue(value) as V;
    },
    [currentQuery]
  );

  const queryObject = useMemo<Partial<TType>>(() => {
    return [...currentQuery.keys()].reduce((acc, key) => {
      acc[key as keyof TType] = getParam(key);
      return acc;
    }, {} as Partial<TType>);
  }, [currentQuery, getParam]);

  return {
    currentQuery,
    modifyQuery,
    modifyAndAppendQuery,
    getParam,
    queryObject,
  };
};
