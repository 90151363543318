import { AccordionContainerProps } from './@types';

export function AccordionContainer({
  entries,
  Item,
  group,
  ItemActions,
  classes,
}: AccordionContainerProps) {
  return (
    <div className={classes.container}>
      {entries.map((e, index) => (
        <Item
          key={`${group.id}-${e?.id ?? index}`}
          {...e}
          item={e}
          Actions={ItemActions}
          classes={classes}
          group={group}
        />
      ))}
    </div>
  );
}

export default AccordionContainer;
