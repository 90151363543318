import React, { Fragment, useContext, useState } from 'react';
import {
  Collapse,
  List,
  createStyles,
  withStyles,
  WithStyles,
  fade,
} from '@material-ui/core';
import AccordionHeader from './AccordionHeader';
import AccordionItem from './AccordionItem';
import AccordionContext from './AccordionContext';
import AccordionContainer from './AccordionContainer';
import { AccordionClassKey, AccordionProps, IdType } from './@types';

const styles = ({ spacing, extensions: { color } }: EdgeCoTheme) =>
  createStyles<AccordionClassKey, AccordionProps>({
    header: {
      backgroundColor: color.backgroundContrastDark,
      color: color.text.contrast,
      '&:hover': {
        backgroundColor: fade(color.backgroundContrastDark, 0.75),
      },
      marginBottom: spacing(1 / 2),
      marginTop: spacing(1 / 2),
      padding: spacing(0, 1 / 2),
      '& .MuiListItemIcon-root, .MuiIconButton-root': {
        color: color.text.contrast,
      },
    },
    actions: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    item: {
      color: color.backgroundContrastDark,
      '& .MuiButtonBase-root': {
        color: color.backgroundContrastDark,
      },
    },
    container: {},
  });

function AccordionList({
  groups,
  classes,
  exclusive,
  onToggle,
  ...rest
}: WithStyles<AccordionClassKey> & AccordionProps) {
  const parentContext = useContext(AccordionContext);
  const [expanded, setExpanded] = useState(new Map<IdType, boolean>());
  const localContext = {
    expanded,
    toggle: (id: IdType) => {
      const newMap = exclusive ? new Map() : new Map(expanded);
      const newState = !expanded.get(id);
      newMap.set(id, newState);
      onToggle?.(id, newState);
      setExpanded(newMap);
    },
  };
  const context = parentContext ?? localContext;
  return (
    <AccordionContext.Provider value={context}>
      <List>
        {groups?.map((group) => {
          const { entries } = group;
          const Header = group.Header ?? rest.Header ?? AccordionHeader;
          const Item = group.Item ?? rest.Item ?? AccordionItem;
          const Container =
            group.Container ?? rest.Container ?? AccordionContainer;
          const HeaderActions = group.HeaderActions ?? rest.HeaderActions;
          const ItemActions = group.ItemActions ?? rest.ItemActions;
          return (
            <Fragment key={`group-${group.id}`}>
              <Header classes={classes} group={group} Actions={HeaderActions} />
              <Collapse
                key={`group-collapse-${group.id}`}
                in={context.expanded.get(group.id)}
              >
                <Container
                  classes={classes}
                  group={group}
                  entries={entries}
                  Item={Item}
                  ItemActions={ItemActions}
                />
              </Collapse>
            </Fragment>
          );
        })}
      </List>
    </AccordionContext.Provider>
  );
}
const Styled = withStyles(styles)(AccordionList);
export { Styled as AccordionList };
export default Styled;
