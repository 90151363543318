import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

import Checkbox, { CheckboxProps } from '../input/Checkbox';
import ListItem from './ListItem';

const useStyles = makeStyles((theme: Theme) => ({
  listStyle: {
    padding: theme.spacing(0, 3),
  },
  listTextStyle: {
    fontSize: '1.6rem',
    fontWeight: 400,
    lineHeight: '2.1rem',
  },
  listItemActionStyle: {
    minWidth: 0,
  },
  selectAllItem: {},
}));

type SelectAllListItemProps = {
  listId: any;
  isChecked: boolean;
  onClick: () => void;
  dense?: boolean;
  checkboxProps?: CheckboxProps;
};

function SelectAllListItem({
  onClick,
  dense,
  listId,
  checkboxProps,
  isChecked,
}: SelectAllListItemProps) {
  const renderedClasses = useStyles();
  return (
    <ListItem
      key={`select-all-${listId}`}
      dense={dense}
      button
      disableGutters={true}
      className={clsx(renderedClasses.listStyle, renderedClasses.selectAllItem)}
      isNarrow={true}
      onClick={onClick}
      text={{
        primary: (
          <div className={renderedClasses.listTextStyle}>Select All</div>
        ),
      }}
      primaryAction={{
        props: {
          className: renderedClasses.listItemActionStyle,
        },
        children: (
          <Checkbox
            isChecked={isChecked}
            labelId={listId.toString()}
            {...checkboxProps}
          />
        ),
      }}
    />
  );
}

export default SelectAllListItem;
