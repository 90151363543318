import { Input, makeStyles } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import { DropdownLineWithChevron } from '../data-visualization/Table/Filters/DropdownLineWithChevron';
import { SelectProps } from './@types';
import Select from './Select';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    display: 'none',
  },
  select: {
    border: 'none',
    minWidth: 140,
    '&:focus': {
      border: 'none',
    },
  },
});

type Props = SelectProps;

function CustomInput(props: any): React.ReactElement<any, any> {
  const classes = useStyles();
  return (
    <>
      <div className={classes.row}>
        <AddIcon fontSize="small" />
        <Input {...props} />
      </div>
      <span>
        <DropdownLineWithChevron chevronAnchor="right" />
      </span>
    </>
  );
}

export function UnderlineSelect({
  children,
  classes: classesProp,
  ...rest
}: Props) {
  const classes = useStyles();
  return (
    <Select
      input={<CustomInput />}
      classes={{
        icon: classes.icon,
        select: classes.select,
        ...classesProp,
      }}
      {...rest}
    >
      {children}
    </Select>
  );
}
