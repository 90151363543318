import {
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  makeStyles,
  Theme,
  useControlled,
  DialogProps,
  useTheme,
  DialogContentProps,
  DialogTitleProps,
} from '@material-ui/core';
import uniqueid from 'lodash.uniqueid';

import clsx from 'clsx';
import { Draggable } from '../common/Draggable';
import IconButton from '../buttons/IconButton';
import ConfirmActions from './ConfirmActions';
import { ModalButton } from './@types';

// TODO: Extract styles into theme
const useStyles = makeStyles(
  ({ spacing, extensions: { color }, shadows }: Theme) => ({
    rootStyle: {
      backgroundColor: color.backgroundContrastLight,
      maxWidth: '800px',
      border: `1px solid ${color.borderLight}`,
      boxSizing: 'content-box',
    },
    titleStyle: {
      backgroundColor: '#DBDBDB',
      padding: spacing(1),
      boxSizing: 'border-box',
      '& > h2': {
        lineHeight: 'inherit',
        color: color.text.dark,
        fontSize: '2.1rem',
      },
    },
    closeButtonStyle: {
      position: 'absolute',
      right: '7px',
      top: '4px',
      padding: spacing(1),
    },
    footerStyle: {
      boxSizing: 'border-box',
      backgroundColor: '#FFF',
      boxShadow: shadows[4],
      borderTop: `1px solid ${color.borderLight}`,
      padding: '5px 19px 10px 0px',
      minHeight: '47px',
    },
    contentStyle: {
      padding: 0,
      boxSizing: 'content-box',
    },
  })
);

// https://github.com/mui-org/material-ui/issues/13394
// TODO: upgrade Material-UI - Material UI is fixing the findDomNode issues in the next release
export type ModalProps = {
  className?: string;
  open?: boolean;
  defaultOpen?: boolean;
  isDraggable?: boolean;
  title?: string;
  hideHeader?: boolean;
  hideFooter?: boolean;
  content: React.ReactElement;
  submitButton?: ModalButton;
  cancelButton?: ModalButton;
  onSubmit?: (...args: any[]) => void;
  onCancel?: (...args: any[]) => void;
  DialogTitleProps?: DialogTitleProps;
  DialogContentProps?: DialogContentProps;
} & Partial<DialogProps>;

export function useModal({
  className,
  isDraggable,
  title,
  content,
  submitButton,
  cancelButton,
  onSubmit,
  onCancel,
  open: openProp,
  defaultOpen,
  hideHeader,
  hideFooter,
  DialogTitleProps: TitleProps,
  DialogContentProps: ContentProps,
  ...rest
}: ModalProps) {
  const theme = useTheme();
  const {
    extensions: { color },
  } = theme;

  const [open, setOpen] = useControlled({
    controlled: openProp,
    default: defaultOpen || false,
    name: 'Modal',
  });

  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };

  const dialogId = uniqueid('dialog-');
  const RenderedPaper = isDraggable ? Draggable(Paper, dialogId) : Paper;
  const handleCancel = () => {
    if (onCancel) onCancel();
    handleClose();
  };
  const handleSubmit = () => {
    handleClose();
    if (onSubmit) onSubmit();
  };
  const Modal = (
    <Dialog
      open={open}
      onClose={handleCancel}
      PaperComponent={RenderedPaper}
      PaperProps={{ className: classes.rootStyle }}
      aria-labelledby={dialogId}
      {...rest}
    >
      {!hideHeader && (
        <DialogTitle
          className={classes.titleStyle}
          style={isDraggable ? { cursor: 'move' } : undefined}
          id={dialogId}
          {...TitleProps}
        >
          {title}
          <IconButton
            variant="close"
            primaryColor={color.borderLight}
            className={classes.closeButtonStyle}
            onClick={handleCancel}
          />
        </DialogTitle>
      )}
      <DialogContent
        className={clsx(className, classes.contentStyle)}
        {...ContentProps}
      >
        {content}
      </DialogContent>
      {!hideFooter && (
        <ConfirmActions
          submitButton={submitButton}
          cancelButton={cancelButton}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
        />
      )}
    </Dialog>
  );

  return { Modal, open, setOpen };
}

export default useModal;
