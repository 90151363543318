import Select from './Select';
import ConfirmSelectContainer from './ConfirmSelectContainer';
import { useConfirmSelectState } from './selectUtil';
import { ConfirmSelectProps } from './@types';

export function ConfirmSelect(props: ConfirmSelectProps) {
  const {
    value: valueProp,
    defaultValue: defaultValueProp,
    editValue: editValueProp,
    renderValue: renderValueProp,
    onEditChange,
    onChange,
    ListContainer,
    ListContainerProps,
    ...childSelectProps
  } = props;

  const { multiple } = childSelectProps;

  const {
    open,
    setOpen,
    editValue,
    handleClose,
    handleSubmit,
    handleCancel,
    handleChange,
  } = useConfirmSelectState(props);

  const renderValue = (val: any) => {
    if (renderValueProp) return renderValueProp(val);
    if (Array.isArray(val)) {
      return val?.join(', ');
    }
    return val;
  };

  return (
    <Select
      {...childSelectProps}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      value={multiple && !editValue ? [] : editValue}
      onChange={handleChange}
      ListContainer={ConfirmSelectContainer}
      displayEmpty={true}
      ListContainerProps={{
        handleSubmit,
        handleCancel,
        cancelButton: { text: 'Cancel' },
        submitButton: { text: 'Apply' },
        ListContainer,
        ListContainerProps,
      }}
      renderValue={renderValue}
    />
  );
}

export default ConfirmSelect;
