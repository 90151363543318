import React, { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import {
  createStyles,
  Typography,
  TypographyProps,
  useControlled,
  WithStyles,
} from '@material-ui/core';
import {
  DayPickerSingleDateController,
  DayPickerSingleDateControllerShape,
} from 'react-dates';
import moment, { Moment } from 'moment';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import DateInput, { DateInputProps } from './DateInput';

const calendarPadding = 10;
const styles = ({
  palette,
  spacing,
  typography: { pxToRem },
  extensions: { color, borders },
}: EdgeCoTheme) =>
  createStyles({
    root: {
      '& .CalendarDay__selected, & .CalendarDay__default': {
        background: color.background,
        border: borders.light,
        outline: 'none',
      },
      '& .DayPickerNavigation_button': {
        height: 15,
        paddingTop: 0,
        top: calendarPadding + 1,
        outline: 'none',
        '& svg': {
          marginTop: -3,
        },
      },
      '& .CalendarMonth_caption': {
        fontSize: pxToRem(14),
        paddingTop: calendarPadding,
        paddingBottom: 20,
        '& strong': {
          fontWeight: 'normal',
        },
      },
      '& .DayPicker_weekHeader': {
        top: 30,
      },
      '& .DayPickerNavigation_leftButton__horizontalDefault': {
        left: calendarPadding,
      },
      '& .DayPickerNavigation_rightButton__horizontalDefault': {
        right: calendarPadding,
      },
      '& .CalendarMonthGrid_month__hidden ': {
        display: 'none',
      },
    },
    start: {},
    end: {},
    title: {
      marginLeft: calendarPadding,
      marginTop: spacing(1),
      marginBottom: spacing(0.5),
      fontWeight: 'bold',
      fontSize: '1.8rem',
    },
    selected: {
      background: color.primary,
      color: palette.primary.contrastText,
      borderRadius: '50%',
      flex: 1,
      margin: 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    listContainer: {
      width: '100%',
    },
    valueContainer: {},
    value: {
      width: 100,
      margin: 'auto',
      textAlign: 'center',
      display: 'flex',
    },
    valueInput: {
      height: 25,
      borderRadius: 0,
    },
  });

export type DateRangePickerProps = WithStyles<typeof styles> & {
  className?: string;
  value?: [Moment, Moment];
  defaultValue?: [Moment, Moment];
  displayMonths?: [Moment | undefined, Moment | undefined];
  onChange?: (startDate: Moment, endDate: Moment) => void;
};

type RenderMonthFunc = Exclude<
  DayPickerSingleDateControllerShape['renderMonthElement'],
  null | undefined
>;

type RenderMonthProps = Parameters<RenderMonthFunc>[0];

const COMPONENT_NAME = 'DateRangePicker';

function DateRangePicker({
  classes,
  className,
  onChange: onChangeProp,
  value: valueProp,
  defaultValue,
  displayMonths,
}: DateRangePickerProps) {
  const [[startDate, endDate], setValue] = useControlled<[Moment, Moment]>({
    controlled: valueProp,
    default: defaultValue || [moment().startOf('D'), moment().startOf('D')],
    name: COMPONENT_NAME,
  });

  const onChange = useCallback(
    (start: Moment, end: Moment) => {
      // The way react-dates stores the months is a little off,
      // it doesn't take into account EST/EDT changes so every date
      // returned will be in your current time zone offset
      onChangeProp?.(
        moment(start.format('YYYY-MM-DD'), 'YYYY-MM-DD').startOf('day'),
        moment(end.format('YYYY-MM-DD'), 'YYYY-MM-DD').endOf('day')
      );
    },
    [onChangeProp]
  );

  const onStartChange = (date: Moment | null) => {
    if (date) {
      const newEnd = endDate.isBefore(date) ? date.clone() : endDate;
      setValue([date.clone(), newEnd.clone()]);
      onChange?.(date.clone(), newEnd.clone());
    }
  };

  const onEndChange = (date: Moment | null) => {
    if (date) {
      const newStart = date.isBefore(startDate) ? date.clone() : startDate;
      setValue([newStart.clone(), date.clone()]);
      onChange?.(newStart.clone(), date.clone());
    }
  };

  const getDateRenderer = (selected: Moment) => (date: Moment) => {
    const isSelected = date.format('MM/DD') === selected.format('MM/DD');
    return isSelected ? (
      <div className={classes.root}>
        <div className={clsx(isSelected && classes.selected)}>
          <div>{date.get('D')}</div>
        </div>
      </div>
    ) : (
      date.get('D').toString()
    );
  };

  const pickerProps: Omit<
    Partial<DayPickerSingleDateControllerShape>,
    'renderMonthText'
  > = {
    daySize: 28,
    hideKeyboardShortcutsPanel: true,
    transitionDuration: 0,
    noBorder: true,
    horizontalMonthPadding: 0,
  };

  const titleProps: TypographyProps = {
    className: classes.title,
    variant: 'h4',
  };

  const renderDateInput = (
    date: Moment,
    onInputChange: DateInputProps['onChange']
  ) => (
    <DateInput
      className={classes.value}
      value={date}
      onChange={onInputChange}
      textFieldProps={{
        InputProps: {
          className: classes.valueInput,
        },
      }}
    />
  );

  const renderMonthElement: RenderMonthFunc = ({ month }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {month.format('MMM, yyyy')}
      </div>
    );
  };

  const startMonthRef = useRef<RenderMonthProps>();
  const endMonthRef = useRef<RenderMonthProps>();
  const updateMonth = (
    date: Moment | undefined,
    ref: MutableRefObject<RenderMonthProps | undefined>
  ) => {
    if (date && ref.current) {
      const { onMonthSelect, onYearSelect } = ref.current;
      let yearOffset = date.year();

      let dateWithCorrectOffset = moment(date.format('YYYY-MM-DD'));
      // we need to subtract 2 because react-dates stores months in an array with extra months for transitions
      let monthOffset = date.month() - 2;
      // if the transition correction results in a negative, wrap back around to the end of the array
      if (monthOffset < 0) {
        monthOffset += 12;
        yearOffset--;
        dateWithCorrectOffset = dateWithCorrectOffset.year(
          dateWithCorrectOffset.year() - 1
        );
      }
      const newMonth = monthOffset.toString();
      const newYear = yearOffset.toString();
      onYearSelect(dateWithCorrectOffset.clone(), newYear);
      onMonthSelect(dateWithCorrectOffset.clone(), newMonth);
    }
  };

  useEffect(() => {
    updateMonth(startDate, startMonthRef);
    updateMonth(endDate, endMonthRef);
  }, [startDate, endDate]);

  const renderStartMonth: RenderMonthFunc = useCallback(
    (props: RenderMonthProps) => {
      startMonthRef.current = props;
      return renderMonthElement(props);
    },
    []
  );

  const renderEndMonth: RenderMonthFunc = useCallback(
    (props: RenderMonthProps) => {
      endMonthRef.current = props;
      return renderMonthElement(props);
    },
    []
  );

  const onFocusChange = () => {
    // onFocusChange is required but we're forcing focused to true
    // Need comments to suppress S1186 warning
  };

  return (
    <>
      <div className={clsx(classes.root, classes.start, className)}>
        <Typography {...titleProps}>From Date</Typography>
        {renderDateInput(startDate, (e) => {
          onStartChange(e);
          updateMonth(e.clone(), startMonthRef);
        })}
        <DayPickerSingleDateController
          {...pickerProps}
          initialVisibleMonth={() => startDate}
          onDateChange={onStartChange}
          onFocusChange={onFocusChange}
          focused={true} // required for initialVisibleMonth
          date={startDate}
          renderDayContents={getDateRenderer(startDate)}
          renderMonthElement={renderStartMonth}
        />
      </div>
      <div className={clsx(classes.root, classes.end, className)}>
        <Typography {...titleProps}>To Date</Typography>
        {renderDateInput(endDate, (e) => {
          onEndChange(e);
          if (e.isBefore(startDate)) {
            updateMonth(e.clone(), startMonthRef);
          }
          updateMonth(e.clone(), endMonthRef);
        })}
        <DayPickerSingleDateController
          {...pickerProps}
          initialVisibleMonth={() => endDate}
          onDateChange={onEndChange}
          onFocusChange={onFocusChange}
          isOutsideRange={(day) => (day as Moment).isBefore(startDate)}
          focused={true} // required for initialVisibleMonth
          date={endDate}
          renderDayContents={getDateRenderer(endDate)}
          renderMonthElement={renderEndMonth}
        />
      </div>
    </>
  );
}

const Styled = withStyles(styles)(DateRangePicker);
export { Styled as DateRangePicker };
export default Styled;
