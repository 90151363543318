import React, { PropsWithChildren } from 'react';
import { createStyles, WithStyles } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { withStyles } from '@material-ui/styles';
import DateRangePicker from 'edgeco/components/dates/DateRangePicker';
import clsx from 'clsx';

const styles = (theme: EdgeCoTheme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      background: theme.extensions.color.background,
      width: 570,
      '& .MuiListItem-root': {
        margin: '10px 0',
      },
    },
    picker: {
      marginTop: 5,
      minWidth: 220,
      flex: 0,
    },
    pickerContainer: {
      display: 'flex',
      height: 280,
    },
    selected: {
      background: theme.extensions.color.primary,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
      flex: 1,
      margin: 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    right: {
      border: theme.extensions.borders.light,
      margin: '40px 0',
    },
    listContainer: {
      width: '100%',
    },
  });

type DateContainerProps = PropsWithChildren<{
  value: string | null;
  inputWidth?: number;
  startDate?: Moment;
  endDate?: Moment;
  startDisplayMonth?: Moment;
  endDisplayMonth?: Moment;
  onChange?: (startDate: Moment, endDate: Moment) => void;
  info?: React.ReactNode;
}> &
  WithStyles<typeof styles>;

export function DateRangeListContainer({
  value,
  children,
  classes,
  inputWidth,
  onChange,
  startDate,
  endDate,
  startDisplayMonth,
  endDisplayMonth,
  info,
}: DateContainerProps) {
  return (
    <div className={clsx(classes.root, value === 'Custom')}>
      <div>
        <div className={classes.pickerContainer}>
          <DateRangePicker
            value={
              startDate && endDate ? [startDate, endDate] : [moment(), moment()]
            }
            className={classes.picker}
            onChange={onChange}
            displayMonths={[startDisplayMonth, endDisplayMonth]}
          />
        </div>
        {info}
      </div>
      <div className={classes.right}></div>
      <div className={classes.listContainer} style={{ minWidth: inputWidth }}>
        {children}
      </div>
    </div>
  );
}

export default withStyles(styles)(DateRangeListContainer);
