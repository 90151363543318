import React, { Attributes } from 'react';
import { ListItemText } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import Checkbox from 'edgeco/components/input/Checkbox';
import ListItem from 'edgeco/components/lists/ListItem';
import { ConfirmSelect } from 'edgeco/components/select';
import { ConfirmSelectProps } from 'edgeco/components/select/@types';
import { Breakpoints } from 'edgeco/components/common/@types';
import FilterContainer, {
  FilterContainerClassKey,
  filterContainerStyles,
} from './FilterContainer';
import { FilterActionType, useFilterContext } from './FilterContext';
import { SelectTableFilter } from '../@types';

type Props = WithStyles<FilterContainerClassKey> & {
  filter: SelectTableFilter;
  index: number;
  depth: number;
  breakpoints?: Breakpoints;
};

function PureSelectFilter({
  filter,
  classes,
  index,
  depth,
  breakpoints,
}: Props) {
  const id = filter.id!;

  const {
    state: { filterValues },
    dispatch,
  } = useFilterContext();

  const filterValue = filterValues[id].editValue;
  const editValue = filterValues[id].specificEditValue;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    dispatch({
      type: FilterActionType.ApplySpecificFilter,
      payload: { value, id },
    });
  };

  const handleEditChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch({
      type: FilterActionType.UpdateSpecificEditFilter,
      payload: { value: event.target.value || [], id },
    });
  };

  const commonFilterProps: Partial<ConfirmSelectProps> & Attributes = {
    key: `report-filter-${depth}-${index}-${filter.field}`,
    classes: { selectMenu: classes.filterMenu },
    className: classes.filterComponent,
  };
  return (
    <FilterContainer
      depth={depth}
      index={index}
      breakpoints={breakpoints}
      filter={filter}
    >
      {filter.multiple ? (
        <ConfirmSelect
          {...commonFilterProps}
          multiple
          native={false}
          value={filterValue}
          editValue={editValue}
          renderValue={(selected) => (selected as string[])?.join(', ')}
          onChange={handleChange}
          onEditChange={handleEditChange}
        >
          {(filter as SelectTableFilter).options.map((o) => (
            <ListItem
              dense
              button
              isNarrow={true}
              key={o.value}
              value={o.value}
            >
              <Checkbox isChecked={editValue?.indexOf(o.value) > -1} />
              <ListItemText primary={o.label ?? o.value} />
            </ListItem>
          ))}
        </ConfirmSelect>
      ) : (
        <ConfirmSelect
          value={filterValue}
          editValue={editValue}
          onChange={handleChange}
          onEditChange={handleEditChange}
          {...commonFilterProps}
        >
          {(filter as SelectTableFilter).options.map((o) => (
            <ListItem key={o.value} value={o.value}>
              {o.label ?? o.value}
            </ListItem>
          ))}
        </ConfirmSelect>
      )}
    </FilterContainer>
  );
}

const SelectFilter = withStyles(filterContainerStyles, {
  name: 'SelectFilter',
})(PureSelectFilter);
export default SelectFilter;
export type SelectFilterProps = React.ComponentProps<typeof SelectFilter>;
