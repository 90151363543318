import React, { ComponentPropsWithRef, ElementType, useState } from 'react';
import Tippy from '@tippyjs/react';
import { followCursor as followCursorPlugin, Instance } from 'tippy.js';
import { uniqueId } from 'lodash';

import TooltipContent from '../common/TooltipContent';
import { WithTooltipProps } from '../@types';

const defaultContentWrapper = (renderArgs: React.ReactNode) => (
  <TooltipContent>{renderArgs}</TooltipContent>
);

/**
 * Wraps the provided component in a tooltip.
 *
 * If you're using a functional component, you will need to wrap it in React.forwardRef, Tippy uses refs for on hover, click, etc
 *
 * @template TProps
 * @template T
 * @param {ComponentTypeWithRef<TProps, T>} Component
 * @returns
 */
export function withTooltip<TProps = any, T extends ElementType = any>(
  Component: React.ComponentType<TProps & ComponentPropsWithRef<T>>
) {
  return ({
    tooltipOptions: {
      followCursor = 'initial',
      contentWrapper = defaultContentWrapper,
      interactive,
      interactiveBorder = 20,
      render,
      content,
      appendTo = document.body,
      trigger = 'mouseenter focus click',
      duration = 0,
      plugins = [],
      onTrigger,
      onUntrigger,
      ...tooltipRest
    },
    ...rest
  }: WithTooltipProps<TProps>) => {
    const refId = uniqueId('shape-');

    // Keeping isMounted & isActive separate.  Mounted is for Lazy initialization in case of the tooltip being an expensive
    const [isMounted, setIsMounted] = React.useState(false);

    // isActive is to provide an active state for the triggering component
    const [isActive, setIsActive] = useState(false);

    const lazyPlugin = {
      fn: () => ({
        onShow: () => setIsMounted(true),
        onHidden: () => setIsMounted(false),
      }),
    };
    const onTriggered = (instance: Instance<any>, evt: Event) => {
      setIsActive(true);
      return onTrigger && onTrigger(instance, evt);
    };

    const onUntriggered = (instance: Instance<any>, evt: Event) => {
      setIsActive(false);
      return onUntrigger && onUntrigger(instance, evt);
    };

    const computedProps = {
      followCursor,
      interactive,
      interactiveBorder,
      render,
      content,
      appendTo,
      trigger,
      duration,
      ...tooltipRest,
    };

    if (render) {
      computedProps.render = (...args) =>
        isMounted ? contentWrapper(render(...args)) : '';
    } else {
      computedProps.content = isMounted ? contentWrapper(content) : '';
    }

    const props: any = {
      ...rest,
      isActive,
      refId,
    };

    return (
      <Tippy
        plugins={[...plugins, followCursorPlugin, lazyPlugin]}
        onTrigger={onTriggered}
        onUntrigger={onUntriggered}
        {...computedProps}
      >
        <Component {...props} />
      </Tippy>
    );
  };
}

export default withTooltip;
