import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

const useStyle = makeStyles(({ spacing, extensions: { color, font } }) => ({
  widgetRoot: {
    width: '100%',
    marginBottom: spacing(2),
  },
  title: {
    marginBottom: spacing(4),
    borderBottom: `1px solid ${color.borderLight}`,
    paddingLeft: spacing(4),
    ...font.title,
  },
  titleTabs: {
    marginBottom: 20,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: 'auto',
    maxWidth: '1100px',
  },
  tabWrapper: {
    position: 'relative',
    borderBottom: `1px solid ${color.borderLightest}`,
  },
  tabCentered: {
    position: 'relative',
    top: 1,
    maxWidth: '1100px',
    margin: 'auto',
  },
}));

type WidgetProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  title: string;
  tabs?: React.ReactNode;
};

export function Widget({ title, children, tabs }: WidgetProps) {
  const classes = useStyle();

  return (
    <div className={classes.widgetRoot}>
      <Typography
        component="h2"
        variant="h2"
        className={clsx(classes.title, tabs && classes.titleTabs)}
      >
        {title}
      </Typography>
      {tabs && (
        <div className={classes.tabWrapper}>
          <div className={classes.tabCentered}>{tabs}</div>
        </div>
      )}
      <div className={classes.content}>{children}</div>
    </div>
  );
}

export default Widget;
