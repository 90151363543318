import { Suspense } from 'react';
import { Environment, loadQuery } from 'react-relay';
import { PartialRouteObject } from 'react-router';
import { RelayRouteObject } from './@types';

// TODO: Placeholder for https://github.com/remix-run/react-router/issues/7200.  React Router is working on data loading capabilities, implement once ready
/**
 * Processes a graph of routes.
 * If a query is provided, uses Relay's loadQuery to start the query process and passes the query reference to the rendered component.
 * https://github.com/remix-run/react-router/issues/7200
 */
export const preloadRouterRoutes = (
  relayEnvironment: Environment,
  routes: RelayRouteObject[]
): PartialRouteObject[] =>
  routes.map((route) => {
    const routerObject: PartialRouteObject = {
      caseSensitive: route.caseSensitive,
      children: route.children,
      path: route.path,
      element: route.element,
    };

    if (route.children) {
      routerObject.children = preloadRouterRoutes(
        relayEnvironment,
        route.children
      );
    }

    // If no query was supplied, we can just return the original element to render
    if (!route.query) return routerObject;

    // Otherwise, with a query, start loading the initial query before render.  Pass the query reference as prop
    const variables = route.initialVariables ?? {};
    const queryRef = loadQuery(
      relayEnvironment,
      route.query,
      variables,
      route.initialQueryOptions,
      route.environmentProviderOptions
    );

    const renderedElement = route.component ? (
      <route.component routeQueryRef={queryRef} />
    ) : (
      route.element
    );

    routerObject.element = route.fallback ? (
      <Suspense fallback={route.fallback}>{renderedElement}</Suspense>
    ) : (
      renderedElement
    );

    return routerObject;
  });
