import {
  BarChart as RechartsBarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  YAxisProps,
} from 'recharts';
import numeral from 'numeral';
import clsx from 'clsx';

import { useTheme } from '@material-ui/core';
import { useColorScheme } from 'edgeco/hooks';
import { XAxisType, Series, WithTooltip, XAxisProps } from '../@types';
import { Bar, YAxis, XAxis, useRechartStyles } from '../common';

type BarChartProps<TData extends object> = WithTooltip<TData> & {
  data: ReadonlyArray<TData>;
  series: Series[];
  xAxisType?: XAxisType;
  width?: number | string;
  height?: number;
  yAxis?: React.ReactElement<YAxisProps>;
  xAxis?: React.ReactElement<XAxisProps>;
  hideTopGridLine?: boolean;
};

export function BarChart<TData extends object>({
  data,
  series,
  xAxisType = 'time',
  width = '100%',
  height = 300,
  yAxis,
  xAxis,
  hideTopGridLine = true,
  ...rest
}: BarChartProps<TData>) {
  const classes = useRechartStyles();
  const colorScheme = useColorScheme();
  const {
    extensions: { color },
  } = useTheme();
  const getColor = (colorSeries: Series, keyIndex: any, cellIndex: any) => {
    return {
      fill:
        colorSeries.fill ??
        colorScheme.forIndex(
          xAxisType === 'time' ? keyIndex : cellIndex,
          xAxisType === 'time' ? series!.length : data!.length
        ),
      activeFill:
        colorSeries.activeFill ??
        colorScheme.forIndex(
          xAxisType === 'time' ? keyIndex : cellIndex,
          xAxisType === 'time' ? series!.length : data!.length,
          true
        ),
    };
  };
  return (
    <ResponsiveContainer width={width} height={height}>
      <RechartsBarChart
        className={clsx(hideTopGridLine && classes.hideTopGridLine)}
        data={data}
      >
        <CartesianGrid
          stroke={color.borderLight}
          strokeWidth={1}
          vertical={false}
        />

        {xAxis ?? <XAxis minTickGap={0} />}
        {yAxis ?? (
          <YAxis
            tickFormatter={(p) => {
              return numeral(p).format('$# a');
            }}
          />
        )}
        {series?.map((s, keyIndex) => {
          return (
            <Bar
              key={`bar-${keyIndex}`}
              {...rest}
              dataKey={s.dataKey}
              metaKey={s.metaKey}
            >
              {data?.map((_, cellIndex) => {
                return (
                  <Cell
                    key={`cell-${cellIndex}`}
                    style={s.style}
                    width={40}
                    stroke={color.borderLight}
                    strokeWidth={1}
                    {...getColor(s, keyIndex, cellIndex)}
                  />
                );
              })}
            </Bar>
          );
        })}
      </RechartsBarChart>
    </ResponsiveContainer>
  );
}

export default BarChart;
