import { makeStyles, WithStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Breakpoints } from 'edgeco/components/common/@types';
import QuarterButtons from 'edgeco/components/dates/QuarterButtons';
import ListItem from 'edgeco/components/lists/ListItem';
import { DateRangeSelect } from 'edgeco/components/select';
import { ConfirmSelectProps } from 'edgeco/components/select/@types';
import { DateSelectChangeEvent } from 'edgeco/components/select/DateRangeSelect/DateRangeSelect';
import moment, { Moment } from 'moment-timezone';
import React, { Attributes, useCallback, useMemo, useState } from 'react';
import { isRangeBounds, periodToRange } from '../../../../libs';
import { DateTableFilter, DateTableFilterOption } from '../@types';
import FilterContainer, {
  FilterContainerClassKey,
  filterContainerStyles,
} from './FilterContainer';
import { FilterActionType, useFilterContext } from './FilterContext';

type Props = WithStyles<FilterContainerClassKey> & {
  filter: DateTableFilter;
  index: number;
  depth: number;
  breakpoints?: Breakpoints;
};

const useStyles = makeStyles<EdgeCoTheme>(({ extensions: { color } }) => ({
  highlight: {
    border: `1.5px solid ${color.highlight}`,
  },
}));

function getKey(option: DateTableFilterOption) {
  if (option.datePeriod) {
    return option.datePeriod;
  }
  return `${option.startDate?.format('MM/DD/YYYY')}-${option.endDate?.format(
    'MM/DD/YYYY'
  )}`;
}

function PureDateFilter({ filter, classes, index, depth, breakpoints }: Props) {
  const id = filter.id!;
  const renderValue = useCallback((value: any) => {
    const { startDate, endDate } = value;
    if (value.label || (value.datePeriod && value.datePeriod !== 'Custom'))
      return value.label ?? value.datePeriod;
    if (isRangeBounds('quarter', startDate, endDate, 'date', true)) {
      return `${startDate.year()}-Q${endDate.quarter()}`;
    }
    if (isRangeBounds('year', startDate, endDate)) {
      return startDate.year().toString();
    }
    if (startDate && endDate) {
      const format = 'MM/DD/YY';
      return `${startDate.format(format)} to ${endDate.format(format)}`;
    }
    return '';
  }, []);

  const options = useMemo(
    () =>
      filter.options.map((o) => {
        const rv = { ...o, key: getKey(o) };
        if (!o.startDate && o.datePeriod) {
          const [start, end] = periodToRange(o.datePeriod);
          rv.startDate = start;
          rv.endDate = end;
        }
        return rv;
      }),
    [filter.options]
  );

  const {
    state: { filterValues, highlightStart },
    dispatch,
  } = useFilterContext();

  const value = useMemo(() => {
    const filterValue = filterValues[id].editValue;
    const rv = options.find((o) => getKey(o) === getKey(filterValue));
    if (!rv) return filterValue;
    return rv;
  }, [filterValues, id, options]);

  let editValue = filterValues[id].specificEditValue ?? value;
  editValue = useMemo(
    () => ({
      ...editValue,
      startDate: editValue.startDate ?? moment().startOf('day'),
      endDate: editValue.endDate ?? moment().endOf('day'),
    }),
    [editValue]
  );

  const setEditValue = useCallback(
    (newValue: any) => {
      dispatch({
        type: FilterActionType.UpdateSpecificEditFilter,
        payload: { value: newValue, id },
      });
    },
    [dispatch, id]
  );

  const [editQuarter, setEditQuarter] = useState<Moment>();
  const handleDateChange = useCallback(
    (event: DateSelectChangeEvent) => {
      const { datePeriod } = event.target.value;
      let { startDate, endDate } = event.target.value;
      if (startDate && endDate) {
        // For now we force it to be EST/EDT, eventually users will be able to pick their local time zone
        // This will let them see the same transactions as TNS
        startDate = moment.tz(
          startDate!.format('YYYY-MM-DD'),
          'America/New_York'
        );
        endDate = moment
          .tz(endDate!.format('YYYY-MM-DD'), 'America/New_York')
          .endOf('day');
        const newValue = { startDate, endDate, datePeriod };
        dispatch({
          type: FilterActionType.ApplySpecificFilter,
          payload: { value: { ...newValue }, id },
        });
        dispatch({
          type: FilterActionType.ClearDateHighlight,
        });
      }
    },
    [dispatch, id]
  );

  const handleEditDateChange = useCallback(
    (event: DateSelectChangeEvent) => {
      setEditValue(event.target.value);
      setEditQuarter(undefined);
    },
    [setEditValue]
  );

  const startDate: Moment = value.startDate || moment();
  const endDate = value.endDate || moment();
  const [displayMonths, setDisplayMonths] = useState([startDate, endDate]);

  const handleQuarterClick = useCallback(
    (start: Moment, end: Moment) => {
      let quarterEnd = end.clone();
      if (quarterEnd.isAfter(moment(), 'date')) quarterEnd = moment();
      setEditQuarter(start.clone());
      setEditValue({ startDate: start.clone(), endDate: quarterEnd.clone() });
      setDisplayMonths([start.clone(), quarterEnd.clone()]);
    },
    [setEditValue]
  );

  const dateClasses = useStyles();

  const commonFilterProps: Partial<ConfirmSelectProps> & Attributes = {
    key: `report-filter-${depth}-${index}-${filter.field}`,
    classes: {
      selectMenu: classes.filterMenu,
    },
    className: clsx(
      classes.filterComponent,
      classes.dateFilter,
      highlightStart && dateClasses.highlight
    ),
  };

  const showQuarters =
    editQuarter ||
    editValue.showQuarters ||
    isRangeBounds(
      'quarter',
      editValue.startDate,
      editValue.endDate,
      'date',
      true
    ) ||
    isRangeBounds('year', editValue.startDate, editValue.endDate);

  return (
    <FilterContainer
      depth={depth}
      index={index}
      breakpoints={breakpoints}
      filter={filter}
    >
      <DateRangeSelect
        value={value}
        editValue={editValue}
        setEditValue={setEditValue}
        startDate={startDate}
        endDate={endDate}
        onChange={handleDateChange}
        onEditChange={handleEditDateChange}
        displayMonths={displayMonths}
        onDisplayMonthsChange={setDisplayMonths}
        renderValue={renderValue}
        {...(commonFilterProps as any)}
        info={
          showQuarters ? (
            <QuarterButtons
              date={
                editValue.endDate.year() === moment().year()
                  ? moment()
                  : editValue.endDate
              }
              onClick={handleQuarterClick}
            />
          ) : null
        }
      >
        {options.map((option) => {
          return (
            <ListItem button key={option.key} value={option as any}>
              {option.label ?? option.datePeriod}
            </ListItem>
          );
        })}
      </DateRangeSelect>
    </FilterContainer>
  );
}

const DateFilter = withStyles(filterContainerStyles, { name: 'DateFilter' })(
  PureDateFilter
);
export default DateFilter;
export type DateFilterProps = React.ComponentProps<typeof DateFilter>;
