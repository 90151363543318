import { Link, LinkProps } from 'react-router-dom';
import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles({
  link: { textDecoration: 'none', display: 'inline-block' },
});

export function LinkButton({ children, className, ...rest }: LinkProps) {
  const classes = useStyles();
  return (
    <Link className={clsx(className, classes.link)} {...rest}>
      <Button variant="outlined">{children}</Button>
    </Link>
  );
}

export default LinkButton;
