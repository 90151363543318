import React, { PropsWithChildren } from 'react';
import { Bar as RechartsBar } from 'recharts';
import { renderChartWrapper } from './renderChartWrapper';
import BarShape from '../shapes/BarShape';
import withTooltip from '../hooks/withTooltip';
import { BarProps, DataKey } from '../@types';

// TODO: Switch references to move away from Recharts DataKey
function BarWrapper<TData>({
  barPattern,
  fill,
  strokeWidth,
  stroke,
  children,
  dataKey,
  tooltipOptions: toolTipOptions = {},
  getTooltipContent,
  metaKey,
  ...rest
}: PropsWithChildren<BarProps<TData>>) {
  const RenderedShape = getTooltipContent ? withTooltip(BarShape) : BarShape;
  return (
    <>
      <RechartsBar
        dataKey={dataKey as DataKey}
        shape={({ payload, value, ...shapeRest }: any) => {
          const chartData = payload as TData;
          return (
            <RenderedShape
              payload={payload}
              value={value}
              tooltipOptions={{
                content:
                  getTooltipContent &&
                  getTooltipContent({
                    value,
                    dataKey,
                    data: chartData as TData,
                    metaKey,
                  }),
                ...toolTipOptions,
              }}
              {...shapeRest}
            />
          );
        }}
        strokeWidth={strokeWidth ?? 4}
        stroke={barPattern && fill}
        {...rest}
      />
    </>
  );
}

export const Bar = renderChartWrapper<BarProps<any>, BarProps<any>>(
  RechartsBar as React.ComponentType<BarProps<any>>,
  BarWrapper,
  {
    xAxisId: 0,
    yAxisId: 0,
    minPointSize: 0,
    isAnimationActive: false,
    barSize: 70,
  }
);

export default Bar;
