import { macgColors } from '../../../assets/color';
import { YAxisProps } from '../@types';

// TODO: Ticks are not applying custom style

// There is no actual implementation of YAxis. Recharts render function grabs the props only.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function YAxisWrapper(_: YAxisProps) {
  return null;
}

YAxisWrapper.displayName = 'YAxis';
YAxisWrapper.defaultProps = {
  allowDuplicatedCategory: true,
  allowDecimals: false,
  hide: false,
  orientation: 'left',
  width: 60,
  height: 0,
  mirror: false,
  yAxisId: 0,
  tickCount: 6,
  type: 'number',
  domain: [0, 'auto'],
  padding: { top: 0, bottom: 0 },
  allowDataOverflow: false,
  scale: 'auto',
  interval: 0,
  tick: { fill: macgColors.text.dark },
  reversed: false,
  tickLine: false,
};

export { YAxisWrapper as YAxis };
export default YAxisWrapper;
