import { useErrorBoundary } from 'edgeco/hooks/useErrorBoundary';
import { useCallback } from 'react';
import { Navigate, Outlet } from 'react-router';
import { useLogger } from '../../libs';

type RedirectErrorBoundaryOutletProps = {
  errorUri?: string;
};

const RedirectErrorBoundaryOutlet = ({
  errorUri = '/error',
}: RedirectErrorBoundaryOutletProps) => {
  const logger = useLogger('React Error');
  const onError = useCallback(
    (caughtError: Error) => {
      logger.error(caughtError);
    },
    [logger]
  );
  const [error] = useErrorBoundary((caughtError) => onError(caughtError));
  return error ? <Navigate to={errorUri} /> : <Outlet />;
};

export default RedirectErrorBoundaryOutlet;
