import { useMemo } from 'react';
import { uniqueId } from 'lodash';

type KeyOrArrayElementKey<T> = T extends Array<infer C> ? keyof C : keyof T;

function addKeys<T extends object, TKey extends KeyOrArrayElementKey<T>>(
  obj: T,
  childKey?: TKey,
  idPrefix?: string,
  idKey: string = 'id'
): T {
  if (Array.isArray(obj)) {
    return [...obj.map((c) => addKeys(c, childKey, idPrefix, idKey))] as T;
  }

  const rv: any = { ...obj, [idKey]: uniqueId(idPrefix) };
  if (childKey) {
    const children = rv[childKey];
    if (children) {
      if (Array.isArray(children)) {
        rv[childKey] = [
          ...children.map((c) => addKeys(c, childKey, idPrefix, idKey)),
        ] as any;
      } else {
        rv[childKey] = addKeys(children, childKey, idPrefix, idKey);
      }
    }
  }
  return rv as T;
}

export function useGeneratedIds<
  T extends object,
  TKey extends KeyOrArrayElementKey<T>
>(obj: T, childKey?: TKey, idPrefix?: string, idKey: string = 'id') {
  return useMemo(() => {
    return addKeys(obj, childKey, idPrefix, idKey);
  }, [childKey, idKey, obj, idPrefix]) as typeof obj;
}

export default useGeneratedIds;
