import React, { useEffect, useMemo, useState } from 'react';
import {
  REQUIRED_GROUP,
  SELECTION_GROUP,
  useTransferList,
} from 'edgeco/components/transfer-list';
import { IdType } from 'react-table';
import TransferList from 'edgeco/components/transfer-list/TransferList';
import produce from 'immer';
import { ColumnDefinition, ColumnDefinitionList } from 'edgeco/types';
import { TransferListMap } from 'edgeco/components/transfer-list/@types';
import { TABLE_EXPANDER_ID } from './constants';
import { useTableContext } from './TableContext';
import { buildListMap, getVisibleColumns } from './Filters';
import { useLogger } from '../../../libs/logging/useLogger';

type ColumnPickerProps<TData extends object> = {
  addText?: string;
  columnDefinitions: ColumnDefinitionList<TData>;
  initialHiddenColumns: IdType<TData>[];
  initialSelectedColumns: IdType<TData>[];
  hasExpander?: boolean;
};

function useColumnPicker<TData extends object>({
  addText,
  initialSelectedColumns,
  columnDefinitions,
  hasExpander,
}: ColumnPickerProps<TData>) {
  const logger = useLogger('components.table.columnPicker');
  const visibleColumns = useMemo(
    () => getVisibleColumns(columnDefinitions),
    [columnDefinitions]
  );

  const required = useMemo(
    () =>
      Object.entries<ColumnDefinition>(visibleColumns as any)
        .filter(([, def]) => def.group === REQUIRED_GROUP)
        .map(([key]) => key as IdType<TData>),
    [visibleColumns]
  );

  const tableContext = useTableContext();
  const { setColumnListMap, columnListDefinition } = tableContext;

  const columnListMap = useMemo(
    () =>
      tableContext.columnListMap ??
      buildListMap(
        getVisibleColumns<TData>(columnDefinitions as any),
        initialSelectedColumns
      ),
    [columnDefinitions, initialSelectedColumns, tableContext.columnListMap]
  );

  const [editMap, setEditMap] = useState<TransferListMap | undefined>(
    produce(columnListMap, () => {})
  );

  const resetColumns = () => {
    logger.debug('Resetting Columns', columnListMap);
    setEditMap(produce(columnListMap, () => {}));
  };

  // Reset the edit state if another component manually updates it
  useEffect(resetColumns, [columnListMap]);

  const transferListState = useTransferList({
    listMap: editMap,
    setListMap: setEditMap,
    listDefinition: columnListDefinition,
  });

  const { listMap } = transferListState;

  const hidden: IdType<TData>[] = useMemo(
    () =>
      [...listMap.entries()].reduce((acc, [key, values]) => {
        if (key !== SELECTION_GROUP)
          acc.push(...values.map((v) => v.id as IdType<TData>));
        return acc;
      }, [] as IdType<TData>[]),
    [listMap]
  );

  const selected: IdType<TData>[] = useMemo(() => {
    const selectedColumns = [...(editMap?.get(SELECTION_GROUP) ?? [])].map(
      (item) => item.id as IdType<TData>
    );
    const tableDefaults = hasExpander ? [TABLE_EXPANDER_ID] : [];
    return [...tableDefaults, ...required, ...selectedColumns];
  }, [editMap, hasExpander, required]);

  const applyColumnState = () => {
    setColumnListMap(editMap);
  };

  return {
    hidden,
    TransferComponent: (
      <TransferList addText={addText} {...transferListState} />
    ),
    selected,
    resetColumns,
    applyColumnState,
  };
}

export default useColumnPicker;
