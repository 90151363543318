import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Overwrite } from 'edgeco/types';
import ConfirmActions, {
  ConfirmActionsClassKey,
  ConfirmActionsProps,
  confirmActionsStyle,
} from '../modal/ConfirmActions';

const styles = (theme: Theme) =>
  createStyles({
    ...confirmActionsStyle(theme),
    listContainer: {},
    actions: {},
  });

type ContainerClassKey =
  | keyof ReturnType<typeof styles>
  | ConfirmActionsClassKey;

type Props<TContainerProps = any> = Overwrite<
  ConfirmActionsProps,
  {
    handleCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
  }
> &
  WithStyles<ContainerClassKey> & {
    inputWidth?: number;
    children?: React.ReactNode;
    title?: string;
    ListContainer?: React.ElementType<TContainerProps> | React.ElementType;
    ListContainerProps?: TContainerProps;
  };

function PureConfirmSelectContainer<T>({
  handleSubmit,
  handleCancel,
  cancelButton,
  submitButton,
  children,
  classes,
  inputWidth,
  ListContainer,
  ListContainerProps,
}: Props<T>) {
  const Container = ListContainer || 'div';
  return (
    <>
      <Container
        {...ListContainerProps}
        className={classes.listContainer}
        {...{ inputWidth }}
      >
        {children}
      </Container>
      <ConfirmActions
        className={classes.actions}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        cancelButton={cancelButton}
        submitButton={submitButton}
      />
    </>
  );
}

const ConfirmSelectContainer = withStyles(styles, {
  name: 'ConfirmSelectContainer',
})(PureConfirmSelectContainer);
export default ConfirmSelectContainer;

export type ConfirmSelectContainerProps = React.ComponentProps<
  typeof ConfirmSelectContainer
>;
