import { ReactNode } from 'react';
import { Box } from '@material-ui/core';

export type TabsContentProps = {
  children: ReactNode;
  index: number;
  value: any;
};

export function TabContentPanel(props: TabsContentProps) {
  const { children, value, index, ...otherProps } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...otherProps}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
export default TabContentPanel;
