// ! Do not use the types in this file directly
// the only way this should be interacted with is through the theme in material-ui via useTheme, makeStyles, etc

import { createMuiTheme, fade } from '@material-ui/core';
import { ExtensionColors } from './color';

const baseTheme = createMuiTheme({});
export function buildStyles(colors: ExtensionColors) {
  return {
    button: {
      accept: {
        padding: baseTheme.spacing(1, 4),
        backgroundColor: colors.primary,
        color: colors.text.contrast,
        '&:hover': {
          backgroundColor: fade(colors.primary, 0.75),
        },
      },
      cancel: {
        // TODO
        backgroundColor: '#A80400',
        color: '#FFF',
        '&:hover': {
          backgroundColor: fade('#A80400', 0.75),
        },
      },
      action: (backgroundColor?: string) => ({
        padding: '6px',
        margin: baseTheme.spacing(1),
        backgroundColor: backgroundColor ?? colors.background,
        '&:hover': {
          backgroundColor: fade(backgroundColor ?? colors.background, 0.75),
        },
      }),
    },
  };
}

export default buildStyles;
