import _ from 'lodash';
import {
  DraggableListItemMap,
  ListTransfer,
  TransferItem,
  TransferLocation,
} from './@types';
import { SELECTION_GROUP } from './constants';

const reorder = (
  list: TransferItem[],
  startIndex: number,
  endIndex: number
): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function reorderListItemMap(
  listMap: DraggableListItemMap,
  source: TransferLocation,
  destination: TransferLocation
): DraggableListItemMap {
  if (_.isEqual(source, destination)) return listMap;

  const current: TransferItem[] = [...(listMap.get(source.locationId) ?? [])];
  const next: TransferItem[] = [...(listMap.get(destination.locationId) ?? [])];
  const target: TransferItem = { ...current[source.index] };
  // moving to same list
  if (source.locationId === destination.locationId) {
    const reordered: TransferItem[] = reorder(
      current,
      source.index,
      destination.index
    );
    return new Map(listMap).set(source.locationId, reordered);
  }

  // moving to different list
  target.currentGroup = destination.locationId;
  // remove from original
  current.splice(source.index, 1);
  // insert into next
  next.splice(destination.index, 0, target);

  const result: DraggableListItemMap = new Map(listMap)
    .set(source.locationId, current)
    .set(destination.locationId, next);

  return result;
}

function moveListMapItem(
  transfer: ListTransfer,
  currentMap: DraggableListItemMap
) {
  const { source, destination } = transfer;
  const target = currentMap.get(source.locationId)?.[source.index];

  // dropped nowhere or not found
  if (!target) {
    return currentMap;
  }

  // Only allow selection to accept all other groups
  // Otherwise, send the item to it's home list
  if (destination.locationId !== SELECTION_GROUP) {
    destination.locationId = target.homeGroup;
  }
  return reorderListItemMap(currentMap, source, destination);
}

export { reorderListItemMap, reorder, moveListMapItem };
