import { PropsWithChildren } from 'react';

import { createStyles, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

const styles = () =>
  createStyles<'container' | 'sticky', { padding?: number }>({
    container: {
      width: '100%',
    },
    sticky: ({ padding = 0 }) => ({
      left: 0,
      position: 'sticky',
      width: `calc(100vw - 17px - ${padding}px)`,
    }),
  });

type Props = WithStyles<ReturnType<typeof styles>> &
  PropsWithChildren<{
    hasScroll?: boolean;
    padding?: number;
    className?: string;
  }>;

function StickLeft({ children, classes, className }: Props) {
  return (
    <div className={clsx(className, classes.container)}>
      <div className={classes.sticky}>{children}</div>
    </div>
  );
}
const Styled = withStyles(styles)(StickLeft);
export { Styled as StickLeft };
export default Styled;
