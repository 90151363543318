import { WithStyles } from '@material-ui/core';
import theme from 'edgeco/assets/theme';
import IconButton from 'edgeco/components/buttons/IconButton';

import AccordionContext from './AccordionContext';
import ListItem from '../ListItem';
import { AccordionClassKey, AccordionHeaderProps } from './@types';
import { ListItemAction } from '../@types';

export function AccordionHeader({
  classes,
  group,
  Actions,
}: WithStyles<AccordionClassKey> & AccordionHeaderProps) {
  const { text, id } = group;
  let action: ListItemAction | undefined;
  if (Actions) {
    action = {
      iconButton: (
        <div
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <Actions group={group}></Actions>
        </div>
      ),
    };
  }
  return (
    <AccordionContext.Consumer>
      {(ctx) => (
        <ListItem
          button
          className={classes.header}
          onClick={() => ctx.toggle(id)}
          text={{
            primary: text,
          }}
          primaryAction={{
            iconButton: (
              <IconButton
                size="small"
                variant={ctx.expanded.get(id) ? 'chevronUp' : 'chevronDown'}
                primaryColor={theme.extensions.color.background}
              />
            ),
          }}
          secondaryAction={action}
        />
      )}
    </AccordionContext.Consumer>
  );
}

export default AccordionHeader;
