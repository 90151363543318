import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

type IndicatorBarProps = {
  active?: boolean;
  color?: string;
};

const useStyles = makeStyles<EdgeCoTheme, IndicatorBarProps>(
  ({ extensions }) => ({
    root: {
      display: 'inline-block',
      alignSelf: 'stretch',
      margin: '1px 0',
      width: 11,
    },
    active: ({ color = extensions.color.primary }) => ({
      background: color,
    }),
  })
);

export function IndicatorBar(props: IndicatorBarProps) {
  const { active } = props;
  const classes = useStyles(props);
  return <div className={clsx(classes.root, active && classes.active)} />;
}

export default IndicatorBar;
