import { PropsWithChildren } from 'react';
import { DefaultFeature, useFeatureFlagContext } from './FeatureFlagContext';

export type FeatureFlagProps<TFeatureNames extends string = DefaultFeature> =
  PropsWithChildren<{
    name: TFeatureNames;
    showIfDisabled?: boolean;
  }>;

export function FeatureFlag<TFeatureNames extends string = DefaultFeature>({
  name,
  showIfDisabled = false,
  children,
}: FeatureFlagProps<TFeatureNames>) {
  const context = useFeatureFlagContext<TFeatureNames>();

  let showComponent = context[name];
  if (showIfDisabled) showComponent = !showComponent;

  if (!showComponent) return null;
  return <>{children}</>;
}

export default FeatureFlag;
