import { createNamedContext } from 'edgeco/hooks/genericContext';
import { LogConfig, LogContextType, LogLevel } from './@types';

let logConfig: LogConfig;
switch (process.env.REACT_APP_DEPLOY_ENV) {
  case 'development-api':
  case 'development':
    logConfig = {
      default: LogLevel.Debug,
      components: LogLevel.Info,
    };
    break;
  default:
    logConfig = {
      default: LogLevel.Error,
    };
    break;
}

export const defaultValue: LogContextType = {
  logLevel: logConfig,
};

export const LogContext = createNamedContext<LogContextType>(
  'Log',
  defaultValue
);
export default LogContext;
export const LogProvider = LogContext.Provider;
