import { PropsWithChildren } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import { ThemeProvider } from '@material-ui/core/styles';
import { appReducer, initialState } from '../state/reducer-factory';
import { LogContext, defaultValue, LogConfig } from '../libs';
import defaultTheme from '../assets/theme';
import { AppStateProvider } from '../state/store';

type EdgeCoProviderProps = {
  theme?: EdgeCoTheme;
  logConfig?: LogConfig;
};

export const EdgeCoProvider = ({
  children,
  theme = defaultTheme,
  logConfig = defaultValue.logLevel,
}: PropsWithChildren<EdgeCoProviderProps>) => {
  return (
    <>
      <AppStateProvider reducer={appReducer} init={initialState}>
        <LogContext.Provider value={{ logLevel: logConfig }}>
          <ThemeProvider theme={theme}>
            {children}
            <CssBaseline />
          </ThemeProvider>
        </LogContext.Provider>
      </AppStateProvider>
    </>
  );
};

export default EdgeCoProvider;
