import { TypedFunction } from 'edgeco/types';
import Event from './Event';

export class AuthEvents {
  userLoaded: Event;

  userUnloaded: Event;

  silentRenewError: Event;

  userSignedIn: Event;

  userSignedOut: Event;

  userSessionChanged: Event;

  constructor() {
    this.userLoaded = new Event('User loaded');
    this.userUnloaded = new Event('User unloaded');
    this.silentRenewError = new Event('Silent renew error');
    this.userSignedIn = new Event('User signed in');
    this.userSignedOut = new Event('User signed out');
    this.userSessionChanged = new Event('User session changed');
  }

  load(user: any, raiseEvent = true) {
    if (raiseEvent) {
      this.userLoaded.raise(user);
    }
  }

  unload() {
    this.userUnloaded.raise();
  }

  addUserLoaded(cb: TypedFunction<any[]>) {
    this.userLoaded.addHandler(cb);
  }

  removeUserLoaded(cb: TypedFunction<any[]>) {
    this.userLoaded.removeHandler(cb);
  }

  addUserUnloaded(cb: TypedFunction<any[]>) {
    this.userUnloaded.addHandler(cb);
  }

  removeUserUnloaded(cb: TypedFunction<any[]>) {
    this.userUnloaded.removeHandler(cb);
  }

  addSilentRenewError(cb: TypedFunction<any[]>) {
    this.silentRenewError.addHandler(cb);
  }

  removeSilentRenewError(cb: TypedFunction<any[]>) {
    this.silentRenewError.removeHandler(cb);
  }

  raiseSilentRenewError(...args: any[]) {
    this.silentRenewError.raise(args);
  }

  addUserSignedIn(cb: TypedFunction<any[]>) {
    this.userSignedIn.addHandler(cb);
  }

  removeUserSignedIn(cb: TypedFunction<any[]>) {
    this.userSignedIn.removeHandler(cb);
  }

  raiseUserSignedIn() {
    this.userSignedIn.raise();
  }

  addUserSignedOut(cb: TypedFunction<any[]>) {
    this.userSignedOut.addHandler(cb);
  }

  removeUserSignedOut(cb: TypedFunction<any[]>) {
    this.userSignedOut.removeHandler(cb);
  }

  raiseUserSignedOut() {
    this.userSignedOut.raise();
  }

  addUserSessionChanged(cb: TypedFunction<any[]>) {
    this.userSessionChanged.addHandler(cb);
  }

  removeUserSessionChanged(cb: TypedFunction<any[]>) {
    this.userSessionChanged.removeHandler(cb);
  }

  raiseUserSessionChanged() {
    this.userSessionChanged.raise();
  }
}
