import React, { createContext, useReducer } from 'react';
import { IAction, IState } from './combined';

export interface AppStateProviderProps {
  children: React.ReactNode;
  reducer: React.Reducer<IState, IAction>;
  init: IState;
}

const defaultContext: any = {};
export const AppStateContext =
  createContext<[IState, React.Dispatch<IAction>]>(defaultContext);

const AppStateProviderInt = (
  props: AppStateProviderProps
): React.ReactElement => {
  const { children, reducer, init } = props;

  // Initialize global state reducers
  const appReducer = useReducer(reducer, init);

  return (
    <AppStateContext.Provider value={appReducer}>
      {children}
    </AppStateContext.Provider>
  );
};

export const AppStateProvider = AppStateProviderInt;
