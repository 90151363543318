import React, { CSSProperties } from 'react';
import { makeStyles } from '@material-ui/core';
import { Cell } from 'react-table';
import clsx from 'clsx';
import { getShadowGradient } from './tableUtils';
import { withTooltip } from '../hooks';

const useStyles = makeStyles(({ extensions: { color, table } }) => ({
  cell: {
    background: color.background,
    padding: table.cell.padding,
    lineHeight: '1.6rem',
    justifySelf: 'stretch',
    minWidth: 100,
  },
  contentWrapper: {
    height: 41 - table.cell.padding * 2,
    display: 'flex',
    alignItems: 'center',
  },
  cellContent: {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  tooltipBox: {
    maxWidth: 'none !important',
  },
  tooltipContent: {
    maxWidth: 265,
  },
  stickyLastLeft: getShadowGradient('right'),
}));

type CellContentProps<D extends object> = {
  cell: Cell<D>;
};

function CellContent<D extends object>(
  { cell }: CellContentProps<D>,
  ref: any
) {
  const classes = useStyles();

  return (
    <div
      className={classes.contentWrapper}
      ref={ref}
      style={{
        width: cell.column.width
          ? `calc(${cell.column.width}px - 14px)`
          : undefined,
      }}
    >
      <div className={classes.cellContent}>{cell.render('Cell')}</div>
    </div>
  );
}

const CellContentRef = React.forwardRef(CellContent);

type Props<D extends object> = {
  cell: Cell<D>;
  hasHorizontalScroll?: boolean;
  className?: string;
};

function TableCell<D extends object>({
  cell,
  hasHorizontalScroll,
  className,
}: Props<D>) {
  const { style, ...cellProps } = cell.getCellProps();
  const classes = useStyles();
  if (style) {
    style.width = cell.column.width;
    style.minWidth = cell.column.minWidth;
  }

  const toolTipStyle: CSSProperties = {};
  if (cell.column.tooltipWidth) {
    toolTipStyle.maxWidth = cell.column.tooltipWidth;
  }

  const CellWithTooltip =
    cell.column.tooltip &&
    cell.value !== undefined &&
    cell.value !== null &&
    (typeof cell.value !== 'string' || cell.value.trim().length > 0)
      ? withTooltip(CellContentRef)
      : CellContentRef;

  return (
    <div className={clsx(classes.cell, className)} {...cellProps} style={style}>
      <CellWithTooltip
        cell={cell as any} // generic type is lost from withTooltip
        tooltipOptions={{
          content: cell.column.tooltip ? (
            <div className={classes.tooltipContent} style={toolTipStyle}>
              {cell.render('Cell')}
            </div>
          ) : undefined,
          followCursor: false,
          className: classes.tooltipBox,
        }}
      />
      {hasHorizontalScroll && cellProps['data-sticky-last-left-td'] && (
        <span className={classes.stickyLastLeft} />
      )}
    </div>
  );
}

export default TableCell;
