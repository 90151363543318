import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, LegendEntryProps>(
  ({ spacing, extensions: { color, font } }) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: spacing(2),
    },
    symbol: (props) => ({
      marginRight: spacing(1),
      border: `2px solid ${color.borderLight}`,
      width: '25px',
      height: '25px',
      background: props.color,
    }),
    label: {
      ...font.infographicLabel,
    },
  })
);

type LegendEntryProps = {
  label: string | Date | number;
  color: string;
};

export function LegendEntry(props: LegendEntryProps) {
  const { label } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <div className={classes.symbol} />
      <span className={classes.label}>{label}</span>
    </div>
  );
}
